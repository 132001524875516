import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Card, useAPI, Table, AuthContext, ViewContext, Button, Grid } from 'components/lib'; // Remove Panel from here
import { Panel } from 'primereact/panel'; // Import Panel from primereact
import { Dropdown } from 'primereact/dropdown'; // Import Dropdown from primereact
import axios from 'axios'; // Import axios
import apps from '../app/apps.json'; // Correctly import the JSON data
import { NewAccountForm } from './NewAccountForm'; // Correctly import the NewAccountForm component 
import './OrganizationList.css'; // Import the CSS file for styling
import Snackbar from '@mui/material/Snackbar';
import Modal from '@mui/material/Modal'; // Import Modal from MUI
import ClientImportWizard from './ClientImportWizard'; // Import the ClientImportWizard component

export function OrganizationList(props){

  const records = useAPI('/api/user/associatedDataConnections');
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  
  const [recordList, setRecordList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [editData, setEditData] = useState(null); // State to manage edit data
  const [filter, setFilter] = useState({ active: {value: null}}); // State to manage filter
  const [clientSaved, setClientSaved] = useState(null); // State to manage client saved notification
  const [isImportWizardOpen, setIsImportWizardOpen] = useState(false); // State to manage ClientImportWizard modal visibility

  const getAppIcon = (shortName) => {
    const app = apps.find(app => app.short_name === shortName);
    return app ? app.icon : '';
  };

  

  const action = (
    <button onClick={() => authContext.switchAccount(clientSaved)}>Setup Data</button>
  );

  const updateRecordList = (records) => {
    if(records?.length) {
      let recList = [];

      records.map((item) => {
        
        recList.push({
          id: item.id,
          account: item.org_Name,
          client: item.org_Name,
          app: getAppIcon(item.app), // Compute app icon based on short_name
          data_Source: item.name || 'Add Data Source', // Default to 'Add Data Source' if no data source
          health_Score: item.name ? item.health_Score : '',
          records_to_Clean: item.name ? item.records_to_Clean : '',
          plan: item.plan,
          last_Updated: item.updated_at,
          active: item.active,
          checked: false
        });
      });

      setRecordList(recList);

    }
    else {
      setRecordList([]);
    }
  }

  useEffect(() => {
    updateRecordList(records?.data)

  }, [records.data]);

  const createNewAccount = () => {
    setEditData(null); // Clear edit data
    setIsModalOpen(true); // Open the modal
  };

  const saveNewAccount = async (data) => {
    try {
      const response = await axios.post('/api/account/newClient', data);
      console.log('New client account created:', response.data);
      setIsModalOpen(false); // Close the modal
      // Refresh the organization list table
      const updatedRecords = await axios.get('/api/user/associatedDataConnections');
      updateRecordList(updatedRecords.data.data);
      setClientSaved(response.data.account.id); // Show client saved notification
    } catch (error) {
      console.error('Failed to create new client account', error);
    }
  };

  const updateAccount = async (data) => {
    console.log('Updating account:', data);
    try {
      const response = await axios.patch(`/api/account/${data.id}`, data);
      console.log('Account updated:', response.data);
      setIsModalOpen(false); // Close the modal
      // Refresh the organization list table
      const updatedRecords = await axios.get('/api/user/associatedDataConnections');
      updateRecordList(updatedRecords.data.data);
      // console.log('Account updated:', response.data.account.id);
      setClientSaved(response.data.account.id); // Show client saved notification
    } catch (error) {
      console.error('Failed to update account', error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const importClients = () => {
    setIsImportWizardOpen(true); // Open the ClientImportWizard modal
  };

  const closeImportWizard = (e, reason) => {
    if(reason === 'backdropClick') {
      return; // Do nothing if the backdrop is clicked
    }
    setIsImportWizardOpen(false); // Close the ClientImportWizard modal
  };

  const loadAccount = (rec, index) => {
    authContext.switchAccount(rec.id, '/account');
    //History.push('/dashboard');
  };

  const switchApp = (rec, index) => {
    authContext.switchAccount(rec.id, "/dashboard");
    //History.push('/dashboard');
  };


  // function importClients(data, callback){
  //   viewContext.modal.show({
  //     title: 'Import Clients from Karbon Practice Management',
  //     text: 'Enter your Karbon API Access Key below and your active organization clients will be automatically connected to LedgerView.',
  //     form: {
  //       access_key: {
  //           label: 'Access Key',
  //           type: 'text',
  //           required: 'true',
  //           default: '',
  //         },
  //     },
  //     buttonText: 'Import',
  //     url: '/api/auditRule/',
  //     method: 'POST'

  //   }, (res) => {

  //     viewContext.notification.show('Client import has started', 'success', true);
  //     callback(res);

  //   });
  // }

  function detail() {
    return(
     
      <Fragment>
  
        <Card>
        <>
          <Grid cols='2'>
            <Card
              title='Clients'
              // loading={accountList?.data}
              minHeight='120px'
            >
            </Card>
  
            <Card
              title='Attention Required'
              subtitle='last 30 days'
              // loading={accountList?.data}
              minHeight='120px'
            >
              
            </Card>
  
          </Grid>
        </>
        </Card>
      </Fragment>
     
          );
  }

  const selectRecord = (id) => {
    var newRecList = []
    recordList.map((rec) => {
      if(rec.id === id) {
        rec.checked = !rec.checked;
      }
      else {
        rec.checked = false
      }
      newRecList.push(rec)
    })
    setRecordList(newRecList)
  }

  function editAccount(data){
    window.location.href = '/account';
    // setEditData(data); // Set edit data
    // setIsModalOpen(true); // Open the modal
  }

  function loadUsers(data){
    window.location.href = '/account/users';
    // setEditData(data); // Set edit data
    // setIsModalOpen(true); // Open the modal
  }

  const filterRecords = (record) => {
    const activeMatch = filter.active?.value !== null ? record.active === filter.active : true;
    return activeMatch;
  };

  const filteredRecordList = recordList.filter(filterRecords);

  const handleFilterChange = (e) => {
    if (e.value === null) {
      setFilter({ ...filter, active: null }); // Clear all filters
    } else {
      setFilter({ ...filter, active: e.value });
    }
  };

  return(
    <Fragment>

      <Card>
      <>
      <Snackbar open={clientSaved !== null} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  autoHideDuration={5000} message="Client info saved." action={action} />
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', marginBottom: '20px' }}>
          <button className="action-button" onClick={createNewAccount}>Create New Client</button>
          <button className="action-button" onClick={importClients}>Import Clients...</button>
          
          <Dropdown 
            value={filter.active} 
            options={[{ label: 'All', value: null }, { label: 'Active', value: true }, { label: 'Inactive', value: false }]} 
            onChange={handleFilterChange} 
            placeholder="Filter by Active Status" 
            className="filter-dropdown" 
          />
        </div>
      <Table
          data={ filteredRecordList }
          // loading={ accountList?.loading }
          search={true}
          sort={true}
          show={['app', 'data_Source', authContext.user?.is_firm ? 'client' : 'account', 'health_Score', 'records_to_Clean', 'last_Updated', 'active']} 
          hyperlink={[
            {
              col: 'data_Source',
              url: '',
              //callback: loadAccount
            },
            {
              col: 'account',
              url: '',
              callback: loadAccount
            },
            {
              col: 'client',
              url: '',
              callback: loadAccount
            }
          ]}
          actions={{ 
            hyperlink: switchApp,
            edit: editAccount,
            // view: loadUsers,
            // selectRecord: selectRecord, 
          }}
          
        >
        </Table>
        </>
      </Card>
      <Modal open={isModalOpen} onClose={closeModal}>
        <div className="modal-content" style={{ width: '50%', margin: 'auto', marginTop: '10%', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }}>
          <NewAccountForm onSave={editData ? updateAccount : saveNewAccount} onCancel={closeModal} editData={editData} />
        </div>
      </Modal>
      <Modal open={isImportWizardOpen} onClose={closeImportWizard}>
        <div className="modal-content" style={{ width: '80%', margin: 'auto', marginTop: '5%', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }}>
          <ClientImportWizard onClose={closeImportWizard} />
        </div>
      </Modal>
    </Fragment>

  );
}
