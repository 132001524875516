import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Card, useAPI, Grid, Button, ViewContext, Badge, Form, AuthContext } from 'components/lib';
import axios from 'axios';
import { Sidebar } from 'primereact/sidebar';
import Markdown from 'markdown-to-jsx';
import apps from './apps.json'; // Correctly import the JSON data
import { connectApp, disconnectApp, findApp, findAppByConnectionId, findConnection } from './app_connection_logic'; // Import the connection logic

export function App_connection(props){

  const [connections, setConnections] = useState([]);
  const [showLib, setShowLib] = useState(true);

  const list = useAPI('/api/app_connection');
  const user = useAPI('/api/user');

  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const [showSettingsPanel, setShowSettingsPanel] = useState(null);
  const [activeSettings, setActiveSettings] = useState(null);
  const [disconnectConfirm, setDisconnectConfirm] = useState(null);

  const appCategories = [
    {
      name: "Data Apps",
      code: "data"
    },
    {
      name: "Notifier Apps",
      code: "notification"
    },
    {
      name: "Practice Management",
      code: "pm"
    },
    {
      name: "CRM Apps",
      code: "crm"
    },
  ];

  const updateConnections = (list) => {
    if(list?.data?.length) {
      var newList = [];
      list.data.map((item) => {
        var app = findApp(item.app);
        item.icon = app.icon;
        item.short_name = item.app;
        item.org_name = item.name;
        item.name = app.name;
        item.prefix = app.prefix;
        item.edit_settings = app.edit_settings;
        newList.push(item);
      });
      setConnections(newList);
    }
  };

  useEffect(() => {
    updateConnections(list);
  }, [list.data]);

  const connectAppHandler = (short_name) => {
    connectApp(short_name, user.data.default_account);
  };

  const disconnectAppHandler = (id) => {
    disconnectApp(id, viewContext, updateConnections);
  };

  const editSettings = (conn) => {
    var app = findAppByConnectionId(conn.id, connections);
    setActiveSettings(app);
    setShowSettingsPanel(app.short_name);
  };

  const showSettings = (data, app) => {
    connectApp(app, user.data.default_account, data);
  };

  return(
    <Fragment>
      {showLib === true &&
      <>
        {appCategories.map((cat) => {
          return(
            <Fragment key={cat.code}>
              <h1 style={{paddingTop: 40, paddingBottom: 10}}>{cat.name}</h1>
              <Grid cols="3">
                {apps.filter(app => app.type === cat.code).map((item) => {
                  var activeConn = findConnection(item, connections);
                  return(
                    <Card key={item.short_name} highlight={activeConn !== undefined}>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <img style={{height: 40, width: 40}} src={item.icon} alt="" />
                        <div style={{paddingTop: '6px', fontSize: '20px', fontWeight: 'bold', paddingLeft: '20px', width: '70%'}}>{item.name}</div>
                        {activeConn === undefined ? 
                          <div style={{minHeight: 30, paddingTop: '10px', width: '120px'}}>
                            {item.coming_soon ? 'Coming soon' : <a href="#" onClick={() => connectAppHandler(item.short_name)}>Connect</a>}
                          </div>
                          :
                          <div style={{minHeight: 30, paddingTop: '10px'}}>
                            <a href="#" onClick={() => editSettings(activeConn)}>Settings</a>
                          </div>
                        }
                      </div>
                    </Card>
                  );
                })}
              </Grid>
            </Fragment>
          );
        })}
      </>
      }

      {showSettingsPanel &&
      <Sidebar visible={showSettingsPanel !== null} position="right" onHide={() => setShowSettingsPanel(null)} style={{width: '40%'}}>
        <div style={{display: 'flex', flexDirection: 'row', paddingBottom: "20px"}}>
          <img style={{height: 40, width: 40}} src={activeSettings?.icon} alt="" />
          <div style={{paddingTop: '6px', fontSize: '20px', fontWeight: 'bold', paddingLeft: '20px', width: '70%'}}>{activeSettings?.name}</div>
        </div>
        <Badge color={activeSettings?.active_conn?.is_active === true ? "green" : "orange"} text={activeSettings?.active_conn?.is_active ? "Connected" : "Not Connected"} />
        {activeSettings?.active_conn?.is_active &&
        <>
          <table style={{marginTop: "10px", marginBottom: "10px"}}>
            <tr style={{height: "30px"}}>
              <td>Connected Since: </td>
              <td>{new Date(activeSettings.active_conn.created_at).toLocaleDateString("en-US", {hour12: true}) + " " + new Date(activeSettings.active_conn.created_at).toLocaleTimeString("en-US", {hour12: true})}</td>
            </tr>
            <tr>
              <td>Last Activity:</td>
              <td>{new Date(activeSettings.active_conn.updated_at).toLocaleDateString("en-US", {hour12: true}) + " " + new Date(activeSettings.active_conn.updated_at).toLocaleTimeString("en-US", {hour12: true})}</td>
            </tr>
          </table>
          <Button action={() => disconnectConfirm ? setDisconnectConfirm(null) : setDisconnectConfirm(activeSettings.active_conn.id)} small text="Disconnect" color="blue"></Button>
          {disconnectConfirm &&
          <>
            <Card highlight>
              <h2>Are you sure you want to disconnect {activeSettings?.name}?</h2>
              <Form 
                title={'Disconnect Application'}
                buttonText={'Yes, Disconnect'}
                cancel={() => setDisconnectConfirm(null)}
                cancelButton="No, Cancel"
                url={'/api/app_connection/disconnect/' + disconnectConfirm}
                method={'PATCH'}
                destructive={false}
                text={'Are you sure you want to disconnect this application? '}
              />
            </Card>
          </>
          }
        </>
        }
        {activeSettings.settings?.form ?
          <>
            <div style={{paddingTop: '30px'}}>
              <h3>Settings</h3>
            </div>
            <Form 
              data={activeSettings.settings?.form}
              buttonText={activeSettings?.active_conn?.is_active ? "Update" : "Connect"}
              submit={(data) => showSettings(data, activeSettings.short_name)}
            />
          </>
          : 
          ((activeSettings?.active_conn === undefined) || (JSON.stringify(activeSettings?.active_conn) === '{}')) &&
          <>
            <div style={{paddingTop: '20px'}}></div>
            <a href="#" onClick={() => connectAppHandler(activeSettings.short_name)}>{activeSettings.short_name === 'qbo' ? <img style={{height: 30}} src='/images/C2QB_green_btn_short_default_2x.png' alt="" /> : "Connect"}</a>
          </>
        }
        {activeSettings?.description &&
        <p>
          <h3>Details</h3>
          <Markdown>{activeSettings?.description}</Markdown>
        </p>
        }
      </Sidebar>
      }
    </Fragment>
  );
}
