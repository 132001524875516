import React, { useState, useEffect, useContext } from 'react';
import { useAPI, Button, AuthContext } from 'components/lib';
import { TreeSelect } from 'primereact/treeselect';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';


import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme


export function RuleOperation(props) {
  
  const authContext = useContext(AuthContext);
  const [expressionVal, setExpressionVal] = useState(null);

  const fieldList = useAPI('/api/appData/fieldList/'+ props.recordType);
  const [nodes, setNodes] = useState(null);
  useEffect(() => {
    if(fieldList.loading === true){
      return;
    }

    // console.log(JSON.stringify(fieldList.data))
    setNodes(fieldList.data);
    
  }, [fieldList]);
  
  const operations = [
    { name: '= (equals)', code: '=' },
    { name: '!= (not equal to)', code: '!=' },
    { name: '< (less than)', code: '<' },
    { name: '<= (less than or equal to)', code: '<=' },
    { name: '> (greater than)', code: '>' },
    { name: '> (greater than or equal to)', code: '>=' },
    { name: 'starts with', code: 'startswith' },
    { name: 'in (is in the list)', code: 'in' }
  ];

  const values = [
    { name: '"" (blank value)', code: '""' },
    { name: 'True', code: 'True' },
    { name: 'False', code: 'False' },
    { name: 'Text (matches)', code: 'text' },
    { name: 'Numeric Value', code: 'numeric' },
    { name: 'Select a Field', code: 'field' },
    { name: 'Select a Date', code: 'date' },
  ];
  
    const [editId, setEditId] = useState("");

    function checkExpressionVal(id, value) {
      console.log(value)
      if((value === 'date') || (value === 'field') || (value === 'text') || (value === 'numeric')) {
        setExpressionVal(value)
      } else {
        setExpressionVal(null);
        props.editRuleCondition(id, "value", value)
      }
    }
  
    // function setExpressionValue(val) {

    //   if(val === null) {
    //     val = '';
    //   }
    //   else {
    //     val = val.toString();
    //   }

    //   setExpressionVal(val)
    // }

    function toggleEdit(id) {
      if(id === editId) {
        setEditId("");
      }
      else {
        setEditId(id);
      }
    }
  
    const renderFieldName = (query) => {
      try {
      // console.log("query - " + query)

      // Step 1: Remove '$..'
      let cleanedQuery = query.replace('$..', '');
      
      // Remove '[*]'
      cleanedQuery = cleanedQuery.replace(/\[\*\]/g, '');

      // Step 2: Replace '.' with '->'
      cleanedQuery = cleanedQuery.replace(/\./g, ' : ');

      // Step 3: Split words on capital letters and add spaces
      cleanedQuery = cleanedQuery.replace(/([A-Z])/g, ' $1').trim(); // Add space before capital letters
      cleanedQuery = cleanedQuery.charAt(0).toUpperCase() + cleanedQuery.slice(1); // Capitalize the first letter

      return cleanedQuery;
      }
      catch {
        return query;
      }
    }
    const renderJSON = (json, depth) => {
      const style = {
        marginLeft: '${depth * 20}px', // Adjust the indentation level as needed
      };
  
      if (json.expression) {
        return (
          <>
          <div style={style} className={depth === 0 ? 'surroundingOutermostNode' : "surroundingNode"}>
            
            {json.operands &&
              json.operands.map((operand, index) => (
                <div key={index}>
                  {renderJSON(operand, depth=depth + 1)}
                  {index !== json.operands.length-1 &&
  
                    <div style={style} className="expression" >
                      <Button className="operatorBtn" text={json.expression.toUpperCase()} small textOnly action={() => props.toggleExpression(json.id)} />
                    </div>
                  }
  
                </div>
              ))}
          </div>
          { authContext.hasAccountSetting("edit_rules") === true &&
            <div style={style} className="expression" ><Button icon="plus-circle" big action={() => props.addOperand(json.id)} /></div>
          }
          </>
        );
      } else {
        return (
          <div style={style} className="jsonNode">
            {(json.id !== editId) &&
              <div>
                <div className="expressionWrapper">
                  {renderFieldName(json.field)} {json.operator} {(json.value || json.value !== "") ? renderFieldName(json.value) : "\"\""}
                </div>
                { authContext.hasAccountSetting("edit_rules") === true &&
                  <div style={{float: 'right', marginRight: '10px'}}>
                    <Button icon="edit" small color="white" className="panelButton" action={() => toggleEdit(json.id)} />
                    <Button icon="trash" small color="white" className="panelButton"  action={() => props.removeRuleCondition(json.id)} />
                    <Button icon="plus-circle" small color="white" action={() => props.addRuleCondition(json.id)} />
                  </div>
                }
              </div>
            }
  
            {(json.id === editId) &&
              <div className="editExpression">
                <div className="expressionWrapper">
                  {renderFieldName(json.field)} {json.operator} {(json.value || json.value !== "") ? renderFieldName(json.value) : "\"\""}
                
                  <div style={{color: '#707070', marginTop: '20px'}}> 
                    <div style={{paddingBottom: '20px'}}>
                      <TreeSelect style={{width: '100%'}} filter showClear  onChange={(e) => props.editRuleCondition(json.id, "field", e.value)}
                        options={nodes} className="md:w-20rem w-full" placeholder={json.field ? renderFieldName(json.field) : 'Select Field'}></TreeSelect>
                    </div>
                    
                    <div style={{paddingBottom: '20px'}}>
                      <Dropdown style={{minWidth: '30%'}} options={operations} optionLabel="name" onChange={(e) => props.editRuleCondition(json.id, "operator", e.value.code)}
                          className="w-full md:w-14rem" placeholder={json.operator ? json.operator : 'Select'} />
                    </div>

                    <div style={{paddingBottom: '20px'}}>
                    {expressionVal !== 'field' && expressionVal !== 'date' && expressionVal !== 'text'  && expressionVal !== 'numeric' &&
                    <Dropdown style={{minWidth: '30%'}} options={values} optionLabel="name" value={expressionVal} onChange={(e) => checkExpressionVal(json.id, e.value.code)}
                          className="w-full md:w-14rem" placeholder={json.value ? renderFieldName(json.value) : "\"\""} />
                    }

                    {expressionVal === 'field' &&
                    <span>
                      <TreeSelect style={{width: '90%'}} filter showClear onChange={(e) => checkExpressionVal(json.id, e.value)}
                          options={nodes} className="md:w-20rem w-full" placeholder={json.value ? renderFieldName(json.value) : 'Select Field'}></TreeSelect>
                      <Button icon="x" small color="white" className="panelButton" action={() => checkExpressionVal(json.id, '')} /> 
                    </span>
                    }

                    {expressionVal === 'date' &&
                    <span>
                      <Calendar value={expressionVal} dateFormat='mm/dd/yyyy' showButtonBar onChange={(e) => checkExpressionVal(json.id, new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit"
                      }).format(e.value))}></Calendar>
                      <Button icon="x" small color="white" className="panelButton" action={() => checkExpressionVal(json.id, '')} /> 
                    </span>
                    }

                    {expressionVal === 'text' &&
                    <span className="p-input-icon-right">
                        <i className="pi pi-times" onClick={(e) => checkExpressionVal(json.id, '')} />
                        <InputText value={json.value ? json.value : ''} onChange={(e) => props.editRuleCondition(json.id, "value", e.target.value)} />
                    </span>
                    }

                    {expressionVal === 'numeric' &&
                    <span className="p-input-icon-right">
                        <i className="pi pi-times" onClick={(e) => checkExpressionVal(json.id, '')} />
                        <InputText keyfilter="num" value={!isNaN(json.value) ? json.value : 0} onChange={(e) => props.editRuleCondition(json.id, "value", e.target.value)} />
                    </span>
                    
                    }

                    </div>

                  </div>
                </div>
                <div style={{float: 'right', marginRight: '10px'}}>
                  <Button icon="chevron-up" small color="white" className="panelButton" action={() => toggleEdit(json.id)} />
                </div>
              </div>
            }
          </div>
        );
      }
    };
  
    return <div style={{width: '100%'}}><div className={'surroundingOutermostNode'}>{renderJSON(props.data, 0)}
      
      <div style={{textAlign: 'center', marginTop: '40px', maxWidth: '550px', alignItems: true, marginLeft: 'auto', marginRight: 'auto'}} className="surroundingNode">
      If all the conditions are TRUE, <br />then this monitor <Button className="operatorBtn" textOnly small text={props.success === true ? "WILL NOT" : "WILL"} action={() => props.toggleRuleSuccess()} /> trigger a notification.
      </div>
    </div></div>;
  }