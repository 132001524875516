import React, { useState, useEffect } from 'react';
import { Button, TextInput, Switch } from 'components/lib'; // Import TextInput and Switch from your project

export function NewAccountForm({ onSave, onCancel, editData }) {
  const [accountName, setAccountName] = useState('');
  const [accountEmail, setAccountEmail] = useState('');
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    console.log('editData - ' + JSON.stringify(editData))
    if (editData) {
      setAccountName(editData.client);
      setAccountEmail(editData.email);
      setIsActive(editData.active);
    }
  }, [editData]);

  const handleSave = () => {
    const data = {
      id: editData?.id,
      name: accountName,
      email: accountEmail,
      plan: 'free', // Default plan
      active: isActive
    };
    onSave(data);
  };

  return (
    <div className="new-account-form">
      <h2>{editData ? 'Edit Client' : 'Create a New Client'}</h2> {/* Add title */}
      <div className="form-group">
        <label>Client Name:</label>
        <TextInput value={accountName} onChange={(name, value) => setAccountName(value)} />
      </div>
      {/* <div className="form-group">
        <label>Main Email:</label>
        <TextInput value={accountEmail} onChange={(name, value) => setAccountEmail(value)} />
      </div> */}
      <div className="form-group">
        <label>Active:</label>
        <Switch checked={isActive} onChange={(name, value) => setIsActive(value)} />
      </div>
      <div className="button-group">
        <Button text="Save" action={handleSave} />
        <Button text="Cancel" action={onCancel} />
      </div>
    </div>
  );
}
