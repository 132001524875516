/***
*
*   ACCOUNT NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import { AuthContext } from 'components/lib';
import './sub.scss';

export function ProfileNav(props){

  const context = useContext(AuthContext);

  return(
    <nav className='subnav'>

      <NavLink exact to='/profile/profile' activeClassName='active'>Profile</NavLink>
      <NavLink exact to='/profile/password' activeClassName='active'>Password</NavLink>
      <NavLink exact to='/profile/2fa' activeClassName='active'>2FA</NavLink>

      { (context.permission?.owner) &&
      <>
        <NavLink exact to='/profile/billing' activeClassName='active'>Billing</NavLink>
      </>
    }
      
    </nav>
  );
}
