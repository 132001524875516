import React, { useState, useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import FeatherIcon from 'feather-icons-react';
import { AutoComplete } from 'primereact/autocomplete';
import './AddTaskForm.css';
import axios from 'axios';

export function AddTaskForm({ statuses, onSave, onCancel, initialTask, allTags, refreshTasks }) {
  const [task, setTask] = useState(initialTask || {
    title: '',
    description: '',
    status: 'Pending',
    due_date: new Date(),
    new_comments: 0,
    task_group: '',
    assigned_to: null,
    subtasksList: [],
    messagesList: [],
    tags: [],
    activities: [] // Add activities list
  });

  const [newComment, setNewComment] = useState('');
  const [parentTask, setParentTask] = useState(null);
  const [tagSuggestions, setTagSuggestions] = useState(allTags);
  const [taskGroups, setTaskGroups] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (initialTask) {
      setTask({
        ...initialTask,
        messagesList: initialTask.messagesList || []
      });
    }
  }, [initialTask]);

  useEffect(() => {
    const fetchTaskGroups = async () => {
      try {
        const response = await axios.get('/api/task/taskGroups');
        setTaskGroups(response.data.data);
      } catch (error) {
        console.error('Error fetching task groups:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/account/activeUsers');
        console.log('users', response.data.data);
        setUsers(response.data.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchTaskGroups();
    fetchUsers();
  }, []);

  const handleTaskFormChange = (e) => {
    const { name, value } = e.target;
    setTask({ ...task, [name]: value });
  };

  const handleDescriptionChange = (e) => {
    setTask({ ...task, description: e.htmlValue });
  };

  const handleDueDateChange = (e) => {
    setTask({ ...task, due_date: e.value });
  };

  const handleTagsChange = (e) => {
    const newTags = e.value.filter((tag, index) => e.value.indexOf(tag) === index);
    setTask({ ...task, tags: newTags });
    setTagSuggestions(allTags.filter(tag => !newTags.includes(tag)));
  };

  const addActivity = (task, field, oldValue, newValue) => {
    const formatDate = (date) => new Date(date).toLocaleDateString();

    const formattedOldValue = field === 'due_date' ? formatDate(oldValue) : Array.isArray(oldValue) ? oldValue.join(', ') : oldValue;
    const formattedNewValue = field === 'due_date' ? formatDate(newValue) : Array.isArray(newValue) ? newValue.join(', ') : newValue;

    const newActivity = {
      user: 'Current User', // Replace with the actual current user
      field,
      oldValue: field === 'description' ? 'N/A' : formattedOldValue,
      newValue: field === 'description' ? 'N/A' : formattedNewValue,
      timestamp: new Date().toISOString()
    };
    return { ...task, activities: [...task.activities, newActivity] };
  };

  const handleSaveTask = () => {
    const originalTask = initialTask || {};
    const fieldsToCheck = ['title', 'description', 'status', 'due_date', 'task_group', 'assigned_to', 'tags'];
    let updatedTask = { ...task };

    fieldsToCheck.forEach(field => {
      if (originalTask[field] !== task[field]) {
        updatedTask = addActivity(updatedTask, field, originalTask[field], task[field]);
      }
    });

    // // Include task_group_id when saving the task
    // const taskGroup = taskGroups.find(group => group.name === task.task_group);
    // if (taskGroup) {
    //   updatedTask.task_group_id = taskGroup.id;
    // }

    onSave(updatedTask);
    onCancel(); // Close the form after saving the task
  };

  const handleAddMessage = () => {
    if (newComment.trim() !== '') {
      const newMessageObj = {
        user_id: 'current_user_id', // Replace with the actual current user ID
        time: new Date().toISOString(),
        message: newComment,
      };
      const updatedMessagesList = [...task.messagesList, newMessageObj];
      const updatedTask = { ...task, messagesList: updatedMessagesList };
      setTask(updatedTask);
      setNewComment('');

      // Save the message to the server
      axios.post('/api/task/saveMessage', { taskId: updatedTask.id, message: newMessageObj }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        console.log('Message saved successfully');
        const savedMessage = response.data.data;
        // Update the message list with the user's name
        setTask({ ...updatedTask, messagesList: [...updatedTask.messagesList, savedMessage] });
        // Refresh the tasks from the server
        refreshTasks();
      }).catch(error => {
        console.error('Error saving message:', error);
      });
    }
  };

  const handleSubtaskClick = (subtask) => {
    setParentTask(task);
    setTask(subtask);
  };

  const handleCompleteSubtask = (subtaskId) => {
    const updatedSubtasksList = task.subtasksList.map(subtask => {
      if (subtask.id === subtaskId) {
        const newStatus = subtask.status === 'Completed' ? 'Not Started' : 'Completed';
        const updatedSubtask = addActivity(subtask, 'status', subtask.status, newStatus);
        return { ...updatedSubtask, status: newStatus };
      }
      return subtask;
    });

    const subtask = task.subtasksList.find(subtask => subtask.id === subtaskId);
    const updatedTask = addActivity({ ...task, subtasksList: updatedSubtasksList }, 'subtask', 'N/A', `${subtask.title} marked as completed`);
    setTask(updatedTask);
    onSave(updatedTask, false);
  };

  const computeCompletedSubtasks = (subtasksList) => {
    return subtasksList?.filter(subtask => subtask.status === 'Completed').length;
  };

  const computeTotalSubtasks = (subtasksList) => {
    return subtasksList?.length;
  };

  const backBreacdcrumbClicked = () => {
    setTask(parentTask);
    setParentTask(null);
  }

  const getTimelineItems = () => {
    const activities = task.activities?.map(activity => ({
      ...activity,
      type: 'activity'
    }));
    const messages = task.messagesList ? task.messagesList?.map(message => ({
      ...message,
      type: 'message'
    })) : [];
    return [...activities, ...messages].sort((a, b) => new Date(b.timestamp || b.time) - new Date(a.timestamp || a.time));
  };

  const toolbarOptions = (
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
      <select className="ql-size">
        <option value="small" />
        <option />
        <option value="large" />
        <option value="huge" />
      </select>
      <select className="ql-color" />
      <select className="ql-background" />
      <select className="ql-font" />
      <button className="ql-clean" />
    </span>
  );

return (
    <div className="task-form-panel">
        <div className="task-form-content">
            <h4>Task Details</h4>
            <button className="close-button" onClick={onCancel}>
                <FeatherIcon icon="x" />
            </button>
            {parentTask && (
                <div className="breadcrumb">
                    <a href="#" onClick={backBreacdcrumbClicked}>Back to {parentTask.title}</a>
                </div>
            )}
            
            <div className="task-details-inline">
                <input type="text" name="title" value={task.title} onChange={handleTaskFormChange} placeholder="Title" />
                {task.deep_link_url && (
                <div className="task-record-link">
                    <a href={task.deep_link_url} target="_blank" rel="noopener noreferrer">Go To Record</a>
                </div>
            )}
            <Dropdown value={task.task_group_id} options={taskGroups.map(group => ({ label: group.name, value: group.id }))} onChange={(e) => setTask({ ...task, task_group_id: e.value })} placeholder="Select Task Group" />
                <Dropdown value={task.assigned_to} options={users.map(user => ({ label: user.name, value: user.id }))} onChange={(e) => setTask({ ...task, assigned_to: e.value })} placeholder="Select User" />
                <Calendar value={task.due_date} onChange={handleDueDateChange} dateFormat="yy-mm-dd" />
                <Dropdown value={task.status} options={statuses.map(status => ({ label: status.title, value: status.title }))} onChange={(e) => setTask({ ...task, status: e.value })} placeholder="Select Status" />
                
                <AutoComplete 
                    value={task.tags} 
                    suggestions={tagSuggestions} 
                    completeMethod={(e) => setTagSuggestions(allTags?.filter(tag => tag.toLowerCase().includes(e.query.toLowerCase())))} 
                    onChange={handleTagsChange} 
                    multiple 
                    placeholder="Add Tags" 
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && e.target.value.trim() !== '') {
                            handleTagsChange({ value: [...task.tags, e.target.value.trim()] });
                            e.preventDefault();
                            e.target.value = ''; // Clear the input box
                        }
                    }}
                    className="autocomplete-tags"
                />
            </div>

            <Editor style={{ height: '200px' }} value={task.description} onTextChange={handleDescriptionChange} placeholder="Description" headerTemplate={toolbarOptions} />
            
            <div className="button-group">
                <Button onClick={handleSaveTask}>{task.id ? 'Save Changes' : 'Save'}</Button>
                <Button onClick={onCancel} className="cancel-button">Cancel</Button>
            </div>
            
            <div className="messages-section">
                <h5>Messages</h5>
                {task.messagesList?.map((message, index) => (
                    <div key={index} className="message">
                        <div className="message-header">
                            <span className="message-user">{message.user}</span>
                            <span className="message-time">{new Date(message.time).toLocaleString()}</span>
                        </div>
                        <div className="message-body" dangerouslySetInnerHTML={{ __html: message.message }} />
                    </div>
                ))}
                <div className="editor-container">
                    <Editor style={{ height: '100px' }} value={newComment} onTextChange={(e) => setNewComment(e.htmlValue)} placeholder="Add a message..." headerTemplate={toolbarOptions} />
                    <Button className="submit-message-button" onClick={handleAddMessage}>Submit Message</Button>
                </div>
            </div>
            
            {/* <div className="subtasks-section">
                <h5>Subtasks: {computeCompletedSubtasks(task.subtasksList)} of {computeTotalSubtasks(task.subtasksList)} Completed</h5>
                {task.subtasksList?.map((subtask, index) => (
                    <div key={index} className="subtask" style={{ fontSize: '16px' }}>
                        <div className="subtask-header">
                            <span className="subtask-check-icon">
                                <FeatherIcon 
                                    icon="check" 
                                    size={20} 
                                    onClick={() => handleCompleteSubtask(subtask.id)} 
                                    className={subtask.status === 'Completed' ? 'completed-check-icon' : 'incomplete-check-icon'}
                                />
                            </span>
                            <span className="subtask-title">
                                <a href="#" onClick={() => handleSubtaskClick(subtask)}>{subtask.title}</a>
                            </span>
                            <span className="subtask-time">{new Date(subtask.due_date).toLocaleDateString()}</span>
                            <span className="subtask-status">{subtask.status}</span>
                        </div>
                    </div>
                ))}
            </div> */}
            <div className="activities-section">
                <h5>Activity Timeline</h5>
                <div className="timeline">
                    {getTimelineItems().map((item, index) => (
                        <div key={index} className="timeline-item">
                            <div className="timeline-icon" style={{marginTop: '18px'}}>
                                <FeatherIcon style={{marginTop: '8px'}} icon={item.type === 'activity' ? 'activity' : 'message-circle'} />
                            </div>
                            <div className="timeline-content">
                                <span className="timeline-time">{new Date(item.timestamp || item.time).toLocaleString()}</span>
                                {item.type === 'activity' ? (
                                    item.field === 'description' ? (
                                        <p><strong>{item.user}</strong> changed the description</p>
                                    ) : item.field === 'subtask' ? (
                                        <p><strong>{item.user}</strong> {item.newValue}</p>
                                    ) : (
                                        <p><strong>{item.user}</strong> changed <strong>{item.field}</strong> from <em>{item.oldValue}</em> to <em>{item.newValue}</em></p>
                                    )
                                ) : (
                                    <p><strong>{item.user}</strong> commented: <em>{item.message}</em></p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
);
}
