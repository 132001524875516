import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/app';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const welcome = () => console.log('Welcome to LedgerView 🚀');
welcome();