import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'components/lib';
import './onboarding.css'; // Import the CSS file for styling
import axios from 'axios'; // Import axios for making API requests

export const OnboardingView = () => {
  const { user, update } = useContext(AuthContext);
  const [companyName, setCompanyName] = useState('');
  const [isAccountingFirm, setIsAccountingFirm] = useState(false);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedUser = {
      ...user,
      accounts: user.accounts.map((account, index) => 
      index === 0 ? { ...account, name: companyName } : account
      ),
      is_firm: isAccountingFirm,
    };
    update(updatedUser);

    try {
      await axios.post('/api/account/onboarding', {
        companyName,
        isAccountingFirm
      });
      await axios.patch('/api/user', { onboarded: true });
      const redirectPath = '/home';
      history.push(redirectPath);
    } catch (error) {
      console.error('Failed to update account:', error);
    }
  };

  return (
    <div className="onboarding-container">
      <h1 className="onboarding-title">Welcome to LedgerView!</h1>
      <form className="onboarding-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label">
            Company Name:
            <input
              type="text"
              className="form-input"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            Are you an accounting or bookkeeping firm that services clients?
            <select
              className="form-select"
              value={isAccountingFirm ? 'yes' : 'no'}
              onChange={(e) => setIsAccountingFirm(e.target.value === 'yes')}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </label>
        </div>
        <button type="submit" className="form-button">Continue</button>
      </form>
    </div>
  );
};