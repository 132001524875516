/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AuthContext, AppNav, Header } from 'components/lib';
import { useHistory } from 'react-router-dom';
import ClassNames from 'classnames';
import Style from './firm.module.scss';
import '../layout.scss'; // globals
import IdleTimeOutHandler from '../idleTImeoutHandler';

export function FirmLayout(props){

  // context & style
  const context = useContext(AuthContext);
  const css = ClassNames([ Style.app, 'with-sidebar' ]);
  const history = useHistory();

  // useEffect(() => {
  //   if (!context.user?.is_firm) {
  //     history.push('/dashboard');
  //   }
  // }, [context.user, history]);

  
  const title = props.title === "Clients" ? !context.user?.is_firm ? "Accounts" : props.title : props.title;
  const[isActive,setIsActive]=useState(true)
  const onLogout = () => {
  context.signout();
    //alert('here')
  }
  return (
    <Fragment>
      <IdleTimeOutHandler 
        onActive={()=>{setIsActive(true)}} 
        onIdle={()=>{onLogout()}}
        // onLogout={()=>{alert('here')}}
        />
      <AppNav
        type='popup'
        items={[
          { label: 'Home', icon: 'home', link: '/home' },
          { label: 'Connections', icon: 'link-2', link: '/org/app_connection' },
        ]}
        bottomItems={[
          { label: 'Profile', icon: 'user', link: '/profile', },
          { label: 'Help', icon: 'help-circle', link: '/help' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={ css }>

        <Header title={ title } layout="firm" />

        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}
