import React, { useState, useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import FeatherIcon from 'feather-icons-react';
import { AddTaskForm } from './AddTaskForm';
import { FilterPanel } from './FilterPanel';
import { users, statuses, dueDateOptions, allTags } from './data';
import { MessagesPanel, useAPI, AuthContext } from 'components/lib';
import { Tooltip } from 'primereact/tooltip';
import './work.css';
import axios from 'axios';

export function TaskList(props) {
  
  const authContext = useContext(AuthContext);
  

  const [tasks, setTasks] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [chatVisible, setChatVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [taskFormVisible, setTaskFormVisible] = useState(false);
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const [filter, setFilter] = useState({
    status: 'Not Completed', // Default to showing only tasks that are not in a status of 'Completed'
    dueDate: null,
    assignedTo: [],
    tags: []
  });
  const [newTask, setNewTask] = useState({
    title: '',
    description: '',
    status: 'Pending',
    due_date: new Date(),
    new_comments: 0,
    task_group: '',
    subtasks: 0,
    completed_subtasks: 0,
    assigned_to: '',
    subtasksList: [],
    messagesList: [],
    tags: [],
    activities: [] // Add activities list
  });

  const filterButtonRef = useRef(null);
  const filterPanelRef = useRef(null);

  const tasksAPI = useAPI('/api/tasks/all');
  useEffect(() => {
    
    if (tasksAPI?.data?.length) {
      setTasks(tasksAPI.data);
    }
  }, [tasksAPI]);


  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (filterPanelRef.current && !filterPanelRef.current.contains(event.target) && !filterButtonRef.current.contains(event.target)) {
  //       setFilterPanelVisible(false);
  //     }
  //   }
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [filterPanelRef]);

  const filterDueDate = (dueDate, filterOption) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const nextWeek = new Date(today);
    nextWeek.setDate(today.getDate() + 7);
    const next14Days = new Date(today);
    next14Days.setDate(today.getDate() + 14);

    switch (filterOption) {
      case 'beforeToday':
        return dueDate < today;
      case 'today':
        return dueDate.toDateString() === today.toDateString();
      case 'tomorrow':
        return dueDate.toDateString() === tomorrow.toDateString();
      case 'thisWeek':
        return dueDate >= today && dueDate <= nextWeek;
      case 'nextWeek':
        return dueDate > nextWeek && dueDate <= next14Days;
      case 'next14Days':
        return dueDate >= today && dueDate <= next14Days;
      default:
        return true;
    }
  };

  // Group tasks by task group
  const groupedTasks = tasks.reduce((groups, task) => {
    const group = groups[task.task_group] || [];
    group.push(task);
    groups[task.task_group] = group;
    return groups;
  }, {});

  const filterTasks = (task) => {
    const statusMatch = filter.status === 'Not Completed' ? task.status !== 'Completed' : filter.status ? task.status === filter.status : true;
    const dueDateMatch = filter.dueDate ? filterDueDate(task.due_date, filter.dueDate) : true;
    const assignedToMatch = filter.assignedTo.length > 0 ? filter.assignedTo.includes(task.assigned_to) : true;
    const tagsMatch = filter.tags.length > 0 ? filter.tags.some(tag => task.tags.includes(tag)) : true;
    return statusMatch && dueDateMatch && assignedToMatch && tagsMatch;
  };

  // Filter tasks based on the filter criteria
  const filteredTasks = tasks.filter(filterTasks);

  // Custom cell renderer for due date
  const DueDateCell = (rowData) => {
    const handleDateChange = (e) => {
      const newDate = e.value;
      const updatedTask = addActivity(rowData, 'due_date', rowData.due_date, newDate);
      updatedTask.due_date = newDate;
      setTasks(tasks.map(task => task.id === rowData.id ? updatedTask : task));
    };

    const isPastDue = rowData.due_date < new Date();
    const isCompleted = rowData.status === 'Completed';

    return (
      <Calendar 
      value={new Date(rowData.due_date)} // Ensure the value is a Date object
      onChange={handleDateChange} 
      dateFormat="yy-mm-dd" 
      inputClassName={isCompleted ? 'completed-task-row' : isPastDue ? 'past-due-task' : ''}
      />
    );
  };

  // Custom cell renderer for status
  const StatusCell = (rowData) => {
    const dropdownRef = useRef(null);

    const handleStatusChange = async (e) => {
      const newStatus = e.value;
      const updatedTask = addActivity(rowData, 'status', rowData.status, newStatus);
      updatedTask.status = newStatus;
      setTasks(tasks.map(task => task.id === rowData.id ? updatedTask : task));

      try {
        await axios.post('/api/task/updateStatus', { taskId: rowData.id, status: newStatus }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log('Task status updated successfully');
      } catch (error) {
        console.error('Error updating task status:', error);
      }
    };

    return (
      <div>
        <Dropdown className="status-cell" ref={dropdownRef} value={rowData.status} options={statuses.map(status => ({ label: status.title, value: status.title }))} onChange={handleStatusChange} autoFocus />
      </div>
    );
  };

  const handleCommentsClick = (task) => {
    setSelectedTask(task);
    setChatVisible(true);
  };

  const closeChat = () => {
    setChatVisible(false);
    setSelectedTask(null);
  };

  const handleSendMessage = (message) => {
    const updatedTask = { ...selectedTask };
    const newMessageObj = {
      user_id: 'current_user_id', // Replace with the actual current user ID
      time: new Date().toISOString(),
      message: message,
    };
    updatedTask.messagesList = [...updatedTask.messagesList, newMessageObj];
    setSelectedTask(updatedTask);
    setTasks(tasks.map(task => task.id === updatedTask.id ? updatedTask : task));

    // Save the message to the server
    axios.post('/api/task/saveMessage', { taskId: updatedTask.id, message: newMessageObj }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      console.log('Message saved successfully');
      const savedMessage = response.data.data;
      // Update the message count and user name on the task list
      setTasks(tasks.map(task => {
        if (task.id === updatedTask.id) {
          return { ...task, messagesList: [...task.messagesList, savedMessage] };
        }
        return task;
      }));
      // Refresh the tasks from the server
      refreshTasks();
    }).catch(error => {
      console.error('Error saving message:', error);
    });
  };

  const refreshTasks = async () => {
    try {
      const tasksResponse = await axios.get('/api/tasks/all');
      setTasks(tasksResponse.data.data);
    } catch (error) {
      console.error('Error refreshing tasks:', error);
    }
  };

  // Custom cell renderer for messages
  const MessagesCell = (rowData) => (
    <div onClick={() => handleCommentsClick(rowData)} className="messages-cell">
      <FeatherIcon icon="message-circle" />
      <Badge value={rowData.messagesList?.length} severity="info" />
    </div>
  );

  // Custom row expansion template for subtasks
  const rowExpansionTemplate = (data) => {
    return (
      <div className="subtask-container">
        <h5>Subtasks for {data.title}</h5>
        <DataTable value={data.subtasksList} responsiveLayout="scroll" className="subtask-table">
          <Column field="title" header="Title" />
          <Column field="description" header="Description" />
          <Column field="status" header="Status" />
          <Column field="due_date" header="Due Date" body={(rowData) => rowData.due_date.toLocaleDateString()} />
          <Column field="assigned_to" header="Assigned To" />
        </DataTable>
      </div>
    );
  };

  const handleAddTaskClick = () => {
    setTaskFormVisible(true);
  };

  const closeTaskForm = () => {
    setTaskFormVisible(false);
    setNewTask({
      title: '',
      description: '',
      status: 'Pending',
      due_date: new Date(),
      new_comments: 0,
      task_group: '',
      subtasks: 0,
      completed_subtasks: 0,
      assigned_to: '',
      subtasksList: [],
      messagesList: [],
      tags: [],
      activities: []
    });
  };

  const handleSaveTask = async (task) => {
    console.log('Saving task:', task);
    try {
      const response = await axios.post('/api/task/save', task, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('Task saved successfully:', response.data);

      // Refresh the list of tasks from the database
      const tasksResponse = await axios.get('/api/tasks/all');
      setTasks(tasksResponse.data.data);

      closeTaskForm(); // Close the form after saving the task
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };

  const handleEditTask = (task) => {
    setNewTask(task);
    setTaskFormVisible(true);
  };

  const clearStatusFilter = () => {
    setFilter({ ...filter, status: null });
  };

  const clearDueDateFilter = () => {
    setFilter({ ...filter, dueDate: null });
  };

  const clearAssignedToFilter = () => {
    setFilter({ ...filter, assignedTo: [] });
  };

  const applyFilter = () => {
    setFilterPanelVisible(false);
  };

  const clearFilter = () => {
    setFilter({
      status: 'Not Completed', // Default to showing only tasks that are not in a status of 'Completed'
      dueDate: null,
      assignedTo: [],
      tags: []
    });
    setFilterPanelVisible(false);
  };

  const ExpanderCell = (rowData) => (
    <div className="editable">
      <FeatherIcon icon="edit" size={20} className="edit-icon" onClick={() => handleEditTask(rowData)} />
    </div>
  );

  const getCardClass = (task) => {
    if (task.status === 'Completed') {
      return 'completed-task-row';
    }

    const today = new Date();
    const dueDate = new Date(task.due_date);
    const timeDiff = dueDate - today;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (daysDiff < 0) {
      return 'task-overdue';
    } else if (daysDiff <= 2) {
      return 'task-due-soon';
    } else {
      return 'task-future';
    }
  };

  const TitleCell = (rowData) => (
    <div className={`editable task-title ${getCardClass(rowData)}`} data-pr-tooltip={rowData.title} data-pr-position="top">
      {rowData.title.length > 27 ? `${rowData.title.substring(0, 27)}...` : rowData.title}
    </div>
  );

  const CompleteTaskCell = (rowData) => {
    const handleCompleteTask = async () => {
      const newStatus = rowData.status === 'Completed' ? 'Not Started' : 'Completed';
      const updatedTask = addActivity(rowData, 'status', rowData.status, newStatus);
      updatedTask.status = newStatus;
      const updatedTasks = tasks.map(task => 
        task.id === rowData.id ? updatedTask : task
      );
      setTasks(updatedTasks);

      try {
        await axios.post('/api/task/updateStatus', { taskId: rowData.id, status: newStatus }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log('Task status updated successfully');
      } catch (error) {
        console.error('Error updating task status:', error);
      }
    };

    return (
      <div onClick={handleCompleteTask} className="complete-task-cell">
        <FeatherIcon icon="check" size={20} className={rowData.status === 'Completed' ? 'completed-check-icon' : 'incomplete-check-icon'} />
      </div>
    );
  };

  // Custom cell renderer for assigned to
  const AssignedToCell = (rowData) => {
    const initials = rowData.assigned_to_name ? rowData.assigned_to_name.split(' ').map(name => name[0]).join('') : '-';
    return (
      <div className="assigned-to-avatar">
        <div className="avatar-circle">
          {initials}
        </div>
      </div>
    );
  };

  const computeCompletedSubtasks = (subtasksList) => {
    return subtasksList.filter(subtask => subtask.status === 'Completed').length;
  };

  const computeTotalSubtasks = (subtasksList) => {
    return subtasksList.length;
  };

  const addActivity = (task, field, oldValue, newValue) => {
    const newActivity = {
      user: 'Current User', // Replace with the actual current user
      timestamp: new Date().toISOString(),
      field,
      oldValue,
      newValue
    };
    return { ...task, activities: [...task.activities, newActivity] };
  };

  const handleTaskFormChange = (e) => {
    const { name, value } = e.target;
    const updatedTask = addActivity(newTask, name, newTask[name], value);
    setNewTask(updatedTask);
  };

  return (
    <div>
      <Tooltip target=".editable.task-title" />
      <nav className='subnav'>
        <NavLink exact to='/app/work/index' activeClassName='active'>
          <FeatherIcon icon="check-square" size={16} className="icon" /> List
        </NavLink>
        <NavLink exact to='/app/work/KanbanBoard' activeClassName='active'>
          <FeatherIcon icon="layout" size={16} className="icon" /> Board
        </NavLink>
      </nav>
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <button className="add-task-button" onClick={handleAddTaskClick}>Add Task</button>
        <button className="filter-button" ref={filterButtonRef} onClick={() => setFilterPanelVisible(!filterPanelVisible)}>Filter</button>
        {filterPanelVisible && (
          <div ref={filterPanelRef}>
            <FilterPanel
              filter={filter}
              setFilter={setFilter}
              statuses={statuses}
              dueDateOptions={dueDateOptions}
              users={users}
              allTags={allTags}
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              clearStatusFilter={clearStatusFilter}
              clearDueDateFilter={clearDueDateFilter}
              clearAssignedToFilter={clearAssignedToFilter}
              closePanel={() => setFilterPanelVisible(false)}
            />
          </div>
        )}
      </div>
      <div>
        {Object.keys(groupedTasks).map(group => (
          <div key={group}>
            <h3>{group}</h3>
            <DataTable value={filteredTasks.filter(task => task.task_group === group)} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} dataKey="id" rowClassName={(rowData) => rowData.status === 'Completed' ? 'task-row completed-task-row' : 'task-row'}>
              <Column body={ExpanderCell} style={{ width: '3em' }} />
              <Column body={CompleteTaskCell} style={{ width: '3em' }} />
              <Column field="title" header="Title" body={TitleCell}></Column>
              <Column field="description" header="Description" style={{ width: '300px' }} body={(rowData) => (
                <div className="editable">
                  {rowData.description.replace(/<\/?p>|<br\s*\/?>/g, " ").replace(/<\/?[^>]+(>|$)/g, "").length > 30 ? `${rowData.description.replace(/<\/?p>|<br\s*\/?>/g, " ").replace(/<\/?[^>]+(>|$)/g, "").substring(0, 30)}...` : rowData.description.replace(/<\/?p>|<br\s*\/?>/g, " ").replace(/<\/?[^>]+(>|$)/g, "")}
                </div>
              )}></Column>
              <Column field="status" header="Status" body={StatusCell}></Column>
              <Column field="due_date" header="Due Date" body={DueDateCell} style={{ width: '200px' }}></Column>
              <Column field="messages" header="Messages" body={MessagesCell}></Column>
              {/* <Column field="subtasks" header="Subtasks" body={(rowData) => `${computeCompletedSubtasks(rowData.subtasksList)}/${computeTotalSubtasks(rowData.subtasksList)}`} style={({ subtasksList }) => subtasksList.length === 0 ? { display: 'none' } : {}}></Column> */}
              <Column field="assigned_to" header="Assigned To" body={AssignedToCell}></Column>
            </DataTable>
          </div>
        ))}
      </div>
      {chatVisible && (
        <MessagesPanel
          selectedTask={selectedTask}
          onClose={closeChat}
          onSendComment={handleSendMessage}
        />
      )}
      {taskFormVisible && (
        <AddTaskForm
          users={users}
          statuses={statuses}
          onSave={handleSaveTask}
          onCancel={closeTaskForm}
          initialTask={newTask}
          allTags={allTags}
          refreshTasks={refreshTasks} // Pass the refreshTasks function
        />
      )}
    </div>
  );
}
