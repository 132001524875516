/***
*
*   AUTHENTICATION
*   Auth provider to manage auth functions throughout
*   the application. <PrivateRoute> component to
*   protect internal application routes from unauthenticated
*   access.
*
**********/

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Redirect, Route } from 'react-router-dom';
import Cookies from 'js-cookie';

// auth context
export const AuthContext = React.createContext();

const useAPI = require('components/lib').useAPI;
const Event = require('components/lib').Event;
const permissions = require('./permissions');

export function AuthProvider(props){

  const cache = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(cache);
  const [orgs, setOrgs] = useState([]);
  const auth = useAPI(user ? '/api/auth' : null);
  const connections = useAPI(user ? '/api/app_connection_names' : null);


  // const WS_URL = 'ws://127.0.0.1:1332';

  // // const socket = useWebSocket(WS_URL);
  // const [ws, setWs] = useState(null);
  
  // const [wsMsgHandler, setWsMsgHandler] = useState((data) => (data) => console.log("default handler: " + data));

  // useEffect(() => {
  //   // Assuming userId is passed as a prop to this component
  //   if(auth.data === undefined ) {
  //     return;
  //   }
  //   console.log("account_id: " + auth.data?.account_id)
  //   const account_id = auth.data?.account_id;
  //   if(account_id === undefined) {
  //     return;
  //   }
  //   const ws = new WebSocket(`${WS_URL}?account_id=${auth.data?.account_id}`);

  //   ws.onopen = () => {
  //     console.log('WebSocket connected');
  //     // Perform any other actions needed upon connection
  //   };

  //   ws.onmessage = (event) => {
  //     // Handle incoming messages
  //     console.log('Message from server ', event.data);
  //     wsMsgHandler(event.data);
  //   };

  //   ws.onerror = (error) => {
  //     // Handle errors
  //     console.error('WebSocket error: ', error);
  //   };

  //   ws.onclose = () => {
  //     console.log('WebSocket disconnected');
  //     // Perform any cleanup or reconnection logic
  //   };

  //   setWs(ws);

  //   // Clean up the WebSocket connection when the component unmounts
  //   return () => {
  //     ws.close();
  //   };

  // }, [auth]); // This effect depends on userId and will re-run if userId changes



  useEffect(() => {

    // update the auth status
    if (!auth.loading && auth.data){

      auth.data.authenticated ? update(auth.data) : signout();

    }
  }, [auth]);

  useEffect(() => {
    if(connections?.data?.length) {
      setOrgs(connections.data);
      localStorage.setItem('orgs', JSON.stringify(connections.data));
    }
  }, [connections.data]);

  // useEffect(() => {
  //   console.log('socket last msg - ' + JSON.stringify(socket.lastMessage));
  // }, [socket.lastMessage]);
  


  // function wsMessageHandler(handler) {
  //   setWsMsgHandler(handler);
  // }
  
  // function sendWsMessage(msg) {
  //   ws?.send(msg);
  // }

  function signin(res, retUrl=''){ 
    if (res.data) {
      localStorage.setItem('user', JSON.stringify(res.data));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('jwt');

      // Store the account ID in a secure cookie
      Cookies.set('accountId', res.data.account_id, { secure: process.env.NODE_ENV === 'production', sameSite: 'lax' });

      Event('signin');

      let redirect = res.data.onboarded ? '/home' : '/welcome';
      if(retUrl !== '') {
        redirect = retUrl;
      }
      console.log(JSON.stringify('[signin] - ' + res.data));
      if (res.data.show_qbo_connect === true) {
        redirect = '/authUri';
      }
      return window.location = redirect;
    }
  }

  async function signout(){

    axios({ method: 'delete', url: '/api/auth' });
    localStorage.clear();
    Cookies.remove('jwt');
    Cookies.remove('accountId');
    window.location = '/signin'

  }

  function hasAccountSetting(val) {
    let bRet = false;

    // over ride for edit_rules setting
    if(val === "edit_rules") {
      return true;
    }

    if(user.account_settings) {
      if(user.account_settings[val]) {
        bRet = user.account_settings[val]
      }
    }

    return bRet;
  }

  async function switchAccount(id, retUrl=''){

    const res = await axios({ 
      
      method: 'post', 
      url: '/api/auth/switch',
      data: { account: id }
    
    });

    if (res.data)
      signin(res, retUrl);

  }

  async function switchApp(id){
    const res = await axios({ 
      
      method: 'post', 
      url: '/api/auth/switchApp',
      data: { appConnection: id }
    
    });

    if (res.data)
      signin(res)
  }

  function update(data){

    if (localStorage.getItem('user')){
      let user = JSON.parse(localStorage.getItem('user'));

      for (let key in data) {
        if (Array.isArray(data[key])) {
          user[key] = data[key]
        }
        else if (typeof data[key] === 'object') {
          for (let innerKey in data[key]){
            user[key][innerKey] = data[key][innerKey]
          }
        }
        else {
          user[key] = data[key];
        }
      }

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
    }
  }


  return (
    <AuthContext.Provider value={{

      user: user,
      signin: signin,
      signout: signout,
      update: update,
      switchAccount: switchAccount,
      switchApp: switchApp,
      hasAccountSetting: hasAccountSetting,
      // sendWsMessage, sendWsMessage,
      // wsMessageHandler, wsMessageHandler,
      orgs: orgs,
      permission: permissions[user?.permission],
      is_firm: user?.is_firm // Add is_firm value to the context

    }}

    {...props} />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props){

  return <Route {...props} render={ data => {

    // check user
    const user = JSON.parse(localStorage.getItem('user'));
    
    if (user){
      if (permissions[user.permission][props.permission]){

        if(props.path.includes('/ai')) {
        
              if((!user.account_settings) || (user.account_settings?.dev === false)) {
              return <Redirect to='/signin' />;
            }
        }

        if( props.path.includes('/firm')) {
        
          if((!user.account_settings) || (user.account_settings?.firm === false)) {
              return <Redirect to='/signin' />;
            }
        }

        // user has no plan
        // if (!user.plan && path !== '/account' && path !== '/signup/plan')
        //   return <Redirect to='/signup/plan'/>;
  
        // user has no subscription
        // if ((user.subscription !== 'active' && user.subscription !== 'trialing') && user.permission !== 'master'){

        //   if (!path.includes('/account/billing') &&
        //       !path.includes('/signup/plan') &&
        //       !path.includes('/account/upgrade') && 
        //       !path.includes('/account')){

        //       return <Redirect to='/account/billing' />

        //   }
        // }

        // user is good
        return <props.render {...props }/>

      }
    }

    // user is not authenticated
    return <Redirect to='/signin' />;

  }}/>
}
