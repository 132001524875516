import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import FeatherIcon from 'feather-icons-react';

export function FilterPanel({ filter, setFilter, statuses, dueDateOptions, users, clearFilter, clearStatusFilter, clearDueDateFilter, clearAssignedToFilter, closePanel, hideStatusFilter, allTags }) {
  const clearTagsFilter = () => {
    setFilter({ ...filter, tags: [] });
  };

  const handleStatusChange = (e) => {
    setFilter({ ...filter, status: e.value });
  };

  const handleDueDateChange = (e) => {
    setFilter({ ...filter, dueDate: e.value });
  };

  const handleAssignedToChange = (e) => {
    setFilter({ ...filter, assignedTo: e.value });
  };

  const handleTagsChange = (e) => {
    setFilter({ ...filter, tags: e.value });
  };

  return (
    <div className="filter-panel">
      <div className="filter-panel-header">
        <h4>Filter Tasks</h4>
        <button className="close-button small-round" onClick={closePanel}>
          <FeatherIcon icon="x" />
        </button>
      </div>
      {!hideStatusFilter && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Dropdown value={filter.status} options={statuses.map(status => ({ label: status.title, value: status.title }))} onChange={handleStatusChange} placeholder="Select Status" />
          <button className="clear-button" onClick={clearStatusFilter}>Clear</button>
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Dropdown value={filter.dueDate} options={dueDateOptions} onChange={handleDueDateChange} placeholder="Select Due Date" />
        <button className="clear-button" onClick={clearDueDateFilter}>Clear</button>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MultiSelect value={filter.assignedTo} options={users.map(user => ({ label: user, value: user }))} onChange={handleAssignedToChange} placeholder="Select Users" />
        <button className="clear-button" onClick={clearAssignedToFilter}>Clear</button>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MultiSelect value={filter.tags} options={allTags?.map(tag => ({ label: tag, value: tag }))} onChange={handleTagsChange} placeholder="Select Tags" />
        <button className="clear-button" onClick={clearTagsFilter}>Clear</button>
      </div>
      <div className="button-group">
        <button onClick={clearFilter} className="cancel-button">Clear All</button>
      </div>
    </div>
  );
}
