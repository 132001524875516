/***
*
*   BILLING
*   Change subscription, update card details or view past invoices
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, AccountNav, TabView, Message, Animate, useAPI } from 'components/lib';

import { BillingPlan } from './plan';
import { BillingCard } from './card';
import { BillingInvoices } from './invoices';

const Messages = require('./messages.json');

export function Billing(props){

  // state/context
  const context = useContext(AuthContext);

  // fetch subscription
  const subscription = useAPI('/api/account/subscription');
  const isPaid = context.user.plan === 'free' ? false : true;
  const labels = ['Plan']; if (isPaid) labels.push('Card', 'Invoices');

  // fetch data app connections
  const connections = useAPI('/api/account/connections');

  return (
    <Fragment>

      <Animate>

        { !subscription?.loading && 
          (subscription?.data?.status !== 'active' && subscription?.data?.status !== 'trialing') &&
          <Message { ...Messages[subscription.data?.status] } />
        }

          <div style={{ marginTop: '20px', marginBottom: '20px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
            <h3 style={{ marginBottom: '10px' }}>Data App Connections</h3>
            <p style={{ fontSize: '16px', color: '#333' }}>Total Connections: <strong>{ connections?.data?.length || 0 }</strong></p>
          </div>

        <TabView name='Billing' labels={ labels }>

          <BillingPlan subscription={ subscription } />

          { isPaid && 
          <BillingCard /> }

          { isPaid && 
          <BillingInvoices /> }

        </TabView>
      </Animate>

    </Fragment>
  );
}
