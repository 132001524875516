import React, { useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { reprtTemplates } from './data';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { dataPeriods } from './data';
import DocumentEditor from "./DocumentEditor";

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function Report() {
  const [reports, setReports] = useState([]);
  const [currentReportIndex, setCurrentReportIndex] = useState(0);
  const [hoveredReportIndex, setHoveredReportIndex] = useState(null);
  const [editingTitle, setEditingTitle] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("Last 12 Months");
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);

  const addNewReport = () => {
    setShowTemplates(true);
  };

  const selectTemplate = (template) => {
    const newReportIndex = reports.length;
    setReports([...reports, { name: `Report ${newReportIndex + 1}`, title: template.name, content: template.content }]);
    setCurrentReportIndex(newReportIndex);
    setShowTemplates(false);
  };

  const handleReportDragEnd = (result) => {
    if (!result.destination) return;
    const updatedReports = reorder(reports, result.source.index, result.destination.index);
    setReports(updatedReports);
    setCurrentReportIndex(result.destination.index);
  };

  const handleDeleteReport = (index) => {
    if (window.confirm(`Are you sure you want to delete this report?`)) {
      const updatedReports = reports.filter((_, i) => i !== index);
      setReports(updatedReports);
      if (index === currentReportIndex) {
        setCurrentReportIndex(updatedReports.length === 0 ? 0 : Math.min(currentReportIndex, updatedReports.length - 1));
      } else if (index === reports.length - 1) {
        setCurrentReportIndex(0);
      } else {
        setCurrentReportIndex(prevIndex => Math.min(prevIndex, updatedReports.length - 1));
      }
    }
  };

  const goToReport = (index) => {
    if (index >= 0 && index < reports.length) {
      setCurrentReportIndex(index);
      setShowTemplates(false);
    }
  };

  const toggleEditingTitle = () => {
    setEditingTitle(!editingTitle);
  };

  const handleTitleChange = (e) => {
    const updatedReports = [...reports];
    updatedReports[currentReportIndex].title = e.target.value;
    setReports(updatedReports);
  };

  const handleTitleBlur = () => {
    const updatedReports = [...reports];
    if (!updatedReports[currentReportIndex].title.trim()) {
      updatedReports[currentReportIndex].title = 'Report';
    }
    setReports(updatedReports);
    toggleEditingTitle();
  };

  return (
    <>
      {/* <nav className='subnav'>
        <NavLink exact to='/app/report/dashboard' activeClassName='active'>Dashboard</NavLink>
        <NavLink exact to='/app/report/index' activeClassName='active'>Reports</NavLink>
      </nav> */}
      <div style={{ display: 'flex', height: 'calc(100vh - 40px)', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ width: '200px', backgroundColor: '#f4f4f4', padding: '10px', boxShadow: '2px 0 5px rgba(0,0,0,0.1)' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h3>Reports</h3>
              <button onClick={addNewReport} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <FeatherIcon icon="plus" size={20} />
              </button>
            </div>
            <DragDropContext onDragEnd={handleReportDragEnd}>
              <Droppable droppableId="droppable-reports">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {reports.map((r, index) => (
                        <Draggable key={index} draggableId={`report-${index}`} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                marginBottom: '10px',
                                cursor: 'pointer',
                                padding: '5px',
                                backgroundColor: currentReportIndex === index ? '#ddd' : 'transparent',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '24px',
                              }}
                              onClick={() => goToReport(index)}
                              onMouseEnter={() => setHoveredReportIndex(index)}
                              onMouseLeave={() => setHoveredReportIndex(null)}
                            >
                              {r.title}
                              {hoveredReportIndex === index && (
                                <button onClick={(e) => { e.stopPropagation();  handleDeleteReport(index)}} style={{ background: 'none', border: 'none', cursor: 'pointer', paddingTop: '6px', color: 'inherit' }}>
                                  <FeatherIcon icon="trash-2" size={16} />
                                </button>
                              )}
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          {reports.length > 0 && !showTemplates &&
          <div style={{ flex: 1, padding: '20px', position: 'relative', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', overflow: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {editingTitle ? (
                <input
                  type="text"
                  value={reports[currentReportIndex].title}
                  onChange={handleTitleChange}
                  onBlur={handleTitleBlur}
                  autoFocus
                  style={{ fontSize: '18px', width: '70%' }}
                />
              ) : (
                <h2 onClick={toggleEditingTitle} style={{ cursor: 'pointer', margin: '0px', width: '70%' }}>
                  {reports[currentReportIndex].title}
                </h2>
              )}
              <div className="dropdown-container" style={{ width: '30%' }}>
                <Dropdown value={selectedPeriod} options={dataPeriods.map(period => ({ label: period, value: period }))} onChange={(e) => setSelectedPeriod(e.value)} placeholder="Select Period" />
                {selectedPeriod === 'Custom' && (
                  <>
                    <Calendar value={customStartDate} onChange={(e) => setCustomStartDate(e.value)} view="month" dateFormat="mm/yy" placeholder="Start Date" />
                    <Calendar value={customEndDate} onChange={(e) => setCustomEndDate(e.value)} view="month" dateFormat="mm/yy" placeholder="End Date" />
                  </>
                )}
              </div>
            </div>
            <DocumentEditor content={reports[currentReportIndex].content} />
          </div>
          }
          {(showTemplates || reports.length === 0) &&
          <div style={{ flex: 1, padding: '20px', display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
            {reprtTemplates.map((template) => (
              <div key={template.value} onClick={() => selectTemplate(template)} style={{ cursor: 'pointer', width: '200px', height: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '1px solid #ddd', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', height: '60px' }}>
                  <FeatherIcon icon={template.icon} size={40} />
                </div>
                <h3>{template.name}</h3>
                <p style={{ textAlign: 'center' }}>{template.description}</p>
              </div>
            ))}
          </div>
          }
        </div>
      </div>
    </>
  );
}