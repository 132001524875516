/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, { useState, useEffect, useContext } from 'react';
import { Card, Chart, Table, Grid, Animate, Feedback, useAPI, Message, AuthContext, Badge, Stat } from 'components/lib';
import GaugeChart from 'react-gauge-chart'
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
        

export function Dashboard(props){

  const appConnections = useAPI('/api/app_connection');
  const stats = useAPI('/api/appData/execMetrics');
  const dataSummary = useAPI('/api/appData/dataProcessedChart');
  const auditData = useAPI('/api/auditRule/resultsSummary');
  const backupData = useAPI('/api/appData/dataProcessedSummary');
  const healthScore = useAPI('/api/appData/healthScore');
  const connectionSummary = useAPI('/api/appData/connectionSummary');
  
  const [connSummary, setConnSummary] = useState(null);
  const [connSummaryTitle, setConnSummaryTitle] = useState("");
  const [dataFlowing, setDataFlowing] = useState(true);


  const authContext = useContext(AuthContext);

  const [recordList, setRecordList] = useState([]);
  const [dataTypeList, setDataTypeList] = useState([]);
  const [currentDataType, setCurrentDataType] = useState(null);

  var records = useAPI('/api/appData/recordsToClean/' + authContext.user.appConnection)
  var dataTypes = useAPI('/api/appData/dataTypes/')


  const StatusRenderer = ({ value }) => (
    <div>
      <Badge style={{display: "inline"}} color={value === 'warning' ? 'yellow' : value === 'error' ? 'orange' : "green"} text={`${value}`} />
    </div>
   );
  // Column Definitions: Defines the columns to be displayed.
 const [colDefs, setColDefs] = useState([
  { headerName: "Type", field: "type", filter: true, floatingFilter: true },
  { headerName: "Name", field: "name", filter: true, floatingFilter: true },
  { headerName: "Last Updated", field: "last_updated_at", valueFormatter: params => { return params.value.toLocaleString(); } },
  { headerName: "Status", field: "status", filter: true, floatingFilter: true, cellRenderer: StatusRenderer }
]);

  const updateRecordList = (records) => {
    //alert(JSON.stringify(records))
    if(records?.length) {
      console.log('records.data.length - ' + records?.length)

      let recList = [];

      records.map((item) => {
        let id = item.id;
        //let data = item.data();
        recList.push({
          id: id,
          checked: false,
          type: item.record_type,
          name: item.name,
          last_updated_at: item.last_updated_at,
          // issue: item.short_description,
          status: item.status
          //recDetails: data.recDetails
        });
      });

      setRecordList(recList);

    }
    else {
      setRecordList([]);
    }
  }

  useEffect(() => {
    console.log(JSON.stringify(records?.data))
    updateRecordList(records?.data)

  }, [records.data]);
  
  useEffect(() => {
    if(dataTypes?.data?.length) {
      
      let recList = [];

      dataTypes.data.map((item) => {
        //let data = item.data();
        recList.push({
          value: item.name,
          label: item.name
        });
      });

      setDataTypeList(recList);

    }

  }, [dataTypes.data]);

  useEffect(() => {
    console.log(JSON.stringify(connectionSummary))
    setConnSummary(connectionSummary.data)

    var title = 'No data flowing from system yet. Please wait.';

    if(connectionSummary?.data) {
      if(connectionSummary?.data?.co_name !== "") {
        title = connectionSummary.data?.co_name + ' is connected to ' + connectionSummary.data?.app_name
      }

      if(connectionSummary?.data?.notif !== "") {
        title = title + " with alerts sent to " + connectionSummary.data?.notif;
      }

      setDataFlowing(true);
    } else {
      setDataFlowing(false);
    }
    setConnSummaryTitle(title);

  }, [connectionSummary]);

  return (
    <Animate type='pop'>

{appConnections.data?.length === 0 ? 
  <Card 
        
        loading={appConnections?.loading}
      >
        <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <h2>Setup your first application data connection to begin using LedgerView</h2>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <h2><a href='/app/app_connection'>App Connections</a></h2>
    </div>
  </Card>

: 

dataFlowing === false ? 
  <Card 
        
        loading={appConnections?.loading}
      >
        <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <h2>No data is detected yet. LedgerView will track changes as they happen in your connected apps.</h2>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <h2><a href='/app/app_connection'>App Connections</a></h2>
    </div>
  </Card>

:

<>
{connectionSummary.loading === false &&
      <Message
        title={connSummaryTitle}
        text={connSummary != null ? ('Last Data Seen At: ' + connSummary?.last_updated_at) : ''}
        type={connSummary != null ? 'success' : 'warning'}
        >
      </Message>
      }

{/* {stats?.data &&
<>
<h2 style={{marginBottom: "0.1cm", marginLeft: "0.1cm"}}>This Month</h2>
<Grid cols='4'>
        <Stat
          loading={ stats?.loading }
          value={ Intl.NumberFormat('en-US', { 
            style: 'currency', 
            currency: 'USD' 
        }).format(stats?.data?.revenue) }
          label='Revenue'
          icon='users'
          change={stats?.data?.revenueChangePct != '' ? stats?.data?.revenueChangePct + '%' : 'n/a'}
        //   change_tooltip={"Last Months Revenue: " + Intl.NumberFormat('en-US', { 
        //     style: 'currency', 
        //     currency: 'USD' 
        // }).format(stats?.data?.revenueLastPeriod)}
        />
        <Stat
          loading={ stats?.loading }
          value={ Intl.NumberFormat('en-US', { 
            style: 'currency', 
            currency: 'USD' 
        }).format(stats?.data?.expense) }
          label='Expenses'
          icon='calendar'
          change={stats?.data?.expenseChangePct != '' ? stats?.data?.expenseChangePct + '%' : 'n/a'}
        />
        <Stat
          loading={ stats?.loading }
          value={ Intl.NumberFormat('en-US', { 
            style: 'currency', 
            currency: 'USD' 
        }).format(stats?.data?.netIncome) }
          label='Net Income'
          icon='check'
          change={stats?.data?.netIncomeChangePct != '' ? stats?.data?.netIncomeChangePct + '%' : 'n/a'}
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.cashflow === '' ? 'n/a' : Intl.NumberFormat('en-US', { 
            style: 'currency', 
            currency: 'USD' 
        }).format(stats?.data?.cashflow) }
          label='90 Day Cash Flow Projection'
          icon='refresh-cw'
        />
      </Grid>
  </>
} */}
    <div style={{display: 'flex'}}>
      <div style={{width:'66%', marginRight: '2%', marginBottom: '40px'}}>
      
      

      

      <Card name='history'>
        <Chart
          type='line'
          legend
          loading={ dataSummary.loading }
          data={ dataSummary.data }
          color={['blue', 'green', 'red']}
        />
      </Card>
      </div>
      <div style={{width:'32%'}}>
        <Card title='Health Score' loading={appConnections?.loading} center={true}>
          <div  style={{minHeight: '238px'}}>
          <GaugeChart id="gauge-chart5"
            nrOfLevels={100}
            arcsLength={[0.3, 0.5, 0.2]}
            colors={['#EA4228', '#F5CD19', '#5BE12C']}
            percent={healthScore.data}
            arcPadding={0.02}
            textColor='#000000'
          />
          {/* <HorizontalBar
          loading={ backupData?.loading }
          data={ backupData?.data }
          color={['red', 'blue']}
        /> */}
          </div>
        </Card>
        </div>
      </div>

   
{/* <div style={{'padding-bottom': '10px'}}>
    <DataTable value={recordList} selectionMode={'checkbox'} sortMode="multiple" tableStyle={{ minWidth: '50rem' }} size={'small'} stripedRows paginator rows={5} rowsPerPageOptions={[25, 50, 100, 200]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}">
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
        <Column field="type" sortable filter header="Type"></Column>
        <Column field="name" sortable filter header="Name"></Column>
        <Column field="last_updated_at" sortable header="Last Updated"></Column>
        <Column field="status" sortable filter header="Status"></Column>
    </DataTable>
 </div> */}
 
      <Grid cols='2'>
        <Card
          title='Clean-Up'
          loading={auditData?.loading}
          minHeight='370px'
          hyperlink="/app/cleanup/index"
        >
          {/* <Chart
            type='donut'
            legend={ true }
            data={ auditData?.data }
            loading={ auditData?.loading }
          /> */}

<Chart
          type='bar'
          loading={ auditData?.loading }
          data={ auditData?.data }
          // color={['green']}
        />
          {/* <Table
            data={ auditData?.data }
            loading={ appConnections?.loading }
            search={false}
            sort={true}
            show={['rule', 'warnings', 'errors']}
            hyperlink={{
              col: 'id',
              url: '/app/DataHistory',
              //callback: loadCompany
            }}
          >
          </Table> */}
        </Card>

        <Card
          title='Activity'
          subtitle='last 30 days'
          loading={backupData?.loading}
          minHeight='370px'
          hyperlink="/app/activity/index"
        >
          <Chart
          type='bar'
          loading={ backupData?.loading }
          data={ backupData?.data }
          color={['green']}
        />
          {/* <Table
            data={ backupData?.data }
            loading={ backupData?.loading }
            search={false}
            sort={true}
            show={['record_type', 'num']}
            hyperlink={{
              col: 'id',
              url: '/app/DataHistory',
              //callback: loadCompany
            }}
          >
          </Table> */}
        </Card>

      </Grid>
      </>
}

{/* 
      <Grid cols='3'>
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.users }
          label='users'
          icon='users'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.active }
          label='active'
          icon='check'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.churned }
          label='churned'
          icon='refresh-cw'
        />
        
      </Grid>

       */}
{/* 
      <Grid cols='2'>
        <Card title='Goals' loading={ progress.loading }>
          { progress?.data?.map(item => {

            return (
              <ProgressBar
                key={ item.label }
                label={ item.label }
                progress={ item.value }
              />
            );

          })}
        </Card>
        <Card title='User Types'>
          <Chart
            type='pie'
            legend={ true }
            data={ userChart.data }
            loading={ userChart.loading }
            color='purple'
          />
        </Card>
      </Grid>

      <Card title='Users'>
        <Table
          search
          data={ table.data }
          loading={ table.loading }
          badge={{ col: 'plan', color: 'blue' }}>
        </Table>
      </Card> */}

      <Feedback />

    </Animate>
  );
}
