import React, { useState, useEffect, useContext, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import FeatherIcon from 'feather-icons-react';
import { ViewContext } from 'components/lib';
import { AddTaskForm } from './AddTaskForm';
import { FilterPanel } from './FilterPanel';
import { users, statuses, dueDateOptions, allTags } from './data';
import './KanbanBoard.css';
import { NavLink } from 'react-router-dom';
import { CommentsPanel, useAPI } from 'components/lib';
import axios from 'axios';

export function KanbanBoard(props) {
  const [tasks, setTasks] = useState([]);
  const [taskFormVisible, setTaskFormVisible] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [newTaskColumn, setNewTaskColumn] = useState(null);
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const [filter, setFilter] = useState({
    status: null,
    dueDate: null,
    assignedTo: [],
    tags: []
  });
  const filterButtonRef = useRef(null);
  const filterPanelRef = useRef(null);
  const context = useContext(ViewContext);

  const tasksAPI = useAPI('/api/tasks/all');
  useEffect(() => {
    console.log('tasksAPI', tasksAPI);
    if (tasksAPI?.data?.length) {
      setTasks(tasksAPI.data);
    }
  }, [tasksAPI]);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (filterPanelRef.current && !filterPanelRef.current.contains(event.target) && !filterButtonRef.current.contains(event.target)) {
  //       setFilterPanelVisible(false);
  //     }
  //   }
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [filterPanelRef]);

  const filterDueDate = (dueDate, filterOption) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const nextWeek = new Date(today);
    nextWeek.setDate(today.getDate() + 7);
    const next14Days = new Date(today);
    next14Days.setDate(today.getDate() + 14);

    switch (filterOption) {
      case 'beforeToday':
        return dueDate < today;
      case 'today':
        return dueDate.toDateString() === today.toDateString();
      case 'tomorrow':
        return dueDate.toDateString() === tomorrow.toDateString();
      case 'thisWeek':
        return dueDate >= today && dueDate <= nextWeek;
      case 'nextWeek':
        return dueDate > nextWeek && dueDate <= next14Days;
      case 'next14Days':
        return dueDate >= today && dueDate <= next14Days;
      default:
        return true;
    }
  };

  const filteredTasks = tasks.filter(task => {
    const statusMatch = filter.status === 'Not Completed' ? task.status !== 'Completed' : filter.status ? task.status === filter.status : true;
    const dueDateMatch = filter.dueDate ? filterDueDate(task.due_date, filter.dueDate) : true;
    const assignedToMatch = filter.assignedTo.length > 0 ? filter.assignedTo.includes(task.assigned_to) : true;
    const tagsMatch = filter.tags.length > 0 ? filter.tags.some(tag => task.tags.includes(tag)) : true;
    return statusMatch && dueDateMatch && assignedToMatch && tagsMatch;
  });

  const groupedTasks = filteredTasks.reduce((groups, task) => {
    const group = groups[task.status] || [];
    group.push(task);
    groups[task.status] = group;
    return groups;
  }, {});

  const columns = statuses.map(status => ({
    id: status.title,
    title: status.title,
    tasks: groupedTasks[status.title] || []
  }));

  function adjustKanbanHeight() {
    const kanbanBoard = document.querySelector('.kanban-board');
    if(kanbanBoard) {
      const boardTop = kanbanBoard?.getBoundingClientRect().top;
      if(kanbanBoard.style)
        kanbanBoard.style.height = `calc(100vh - ${boardTop}px - 20px)`;
    }
  }
  
  window.addEventListener('load', adjustKanbanHeight);
  window.addEventListener('resize', adjustKanbanHeight);
  
  const addActivity = (task, field, oldValue, newValue) => {
    const newActivity = {
      user: 'Current User', // Replace with the actual current user
      field,
      oldValue,
      newValue,
      timestamp: new Date().toISOString()
    };
    return { ...task, activities: [...task.activities, newActivity] };
  };

  const handleCardMove = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const updatedTasks = [...tasks];
    const sourceColumnTasks = groupedTasks[source.droppableId] ? [...groupedTasks[source.droppableId]] : [];
    const [movedTask] = sourceColumnTasks.splice(source.index, 1);
    const oldStatus = movedTask.status;
    movedTask.status = destination.droppableId;

    const updatedMovedTask = addActivity(movedTask, 'status', oldStatus, movedTask.status);

    const destinationColumnTasks = groupedTasks[destination.droppableId] ? [...groupedTasks[destination.droppableId]] : [];
    destinationColumnTasks.splice(destination.index, 0, updatedMovedTask);

    destinationColumnTasks.forEach((task, index) => {
      task.order = index;
    });

    const newTasks = updatedTasks.map(task => {
      if (task.id === updatedMovedTask.id) {
        return updatedMovedTask;
      }
      return task;
    });

    setTasks(newTasks);
  };

  const getCardClass = (task) => {
    const today = new Date();
    const dueDate = new Date(task.due_date);
    const timeDiff = dueDate - today;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (daysDiff < 0) {
      return 'task-overdue';
    } else if (daysDiff <= 2) {
      return 'task-due-soon';
    } else {
      return 'task-future';
    }
  };

  const handleEditClick = (task) => {
    setCurrentTask(task);
    setTaskFormVisible(true);
  };

  const handleCompleteClick = async (task) => {
    const updatedTask = addActivity(task, 'status', task.status, 'Completed');
    updatedTask.status = 'Completed';
    const updatedTasks = tasks.map(t => 
      t.id === task.id ? updatedTask : t
    );
    setTasks(updatedTasks);

    try {
      await axios.post('/api/task/updateStatus', { taskId: task.id, status: 'Completed' }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Task status updated successfully');
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  };

  const closeTaskForm = () => {
    setTaskFormVisible(false);
    setCurrentTask(null);
    setNewTaskColumn(null);
  };

  const handleSaveTask = async (task, close = true) => {
    try {
      const response = await axios.post('/api/task/save', task, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('Task saved successfully:', response.data);

      // Refresh the list of tasks from the database
      refreshTasks();

      if (close) {
        closeTaskForm(); // Close the form after saving the task
      }
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };

  const refreshTasks = async () => {
    try {
      const tasksResponse = await axios.get('/api/tasks/all');
      setTasks(tasksResponse.data.data);
    } catch (error) {
      console.error('Error refreshing tasks:', error);
    }
  };

  const renderCard = (task, index) => (
    <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
      {(provided) => (
        <div
          className={`kanban-card ${getCardClass(task)}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <h4>{task.title}</h4>
          <p>{task.description.replace(/<\/?p>|<br\s*\/?>/g, " ").replace(/<\/?[^>]+(>|$)/g, "").length > 30 ? `${task.description.replace(/<\/?p>|<br\s*\/?>/g, " ").replace(/<\/?[^>]+(>|$)/g, "").substring(0, 30)}...` : task.description.replace(/<\/?p>|<br\s*\/?>/g, " ").replace(/<\/?[^>]+(>|$)/g, "")}</p>
          <p>Due: {new Date(task.due_date).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })}</p>
          <div className="kanban-card-icons">
            <FeatherIcon icon="edit" onClick={() => handleEditClick(task)} />
            <FeatherIcon icon="check-circle" onClick={() => handleCompleteClick(task)} />
          </div>
          <div className="kanban-card-avatar" style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
            <div className="avatar-circle">
              {task.assigned_to_name ? task.assigned_to_name.split(' ').map(name => name[0]).join('') : '-'}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
  
  const handleAddTaskClick = () => {
    setTaskFormVisible(true);
    setCurrentTask(null);
    setNewTaskColumn(null);
  };

  const clearStatusFilter = () => {
    setFilter({ ...filter, status: null });
  };

  const clearDueDateFilter = () => {
    setFilter({ ...filter, dueDate: null });
  };

  const clearAssignedToFilter = () => {
    setFilter({ ...filter, assignedTo: [] });
  };

  const applyFilter = () => {
    setFilterPanelVisible(false);
  };

  const clearFilter = () => {
    setFilter({
      status: null,
      dueDate: null,
      assignedTo: []
    });
    setFilterPanelVisible(false);
  };

  return (
    <div>
      <nav className='subnav'>
        <NavLink exact to='/app/work/index' activeClassName='active'>List</NavLink>
        <NavLink exact to='/app/work/KanbanBoard' activeClassName='active'>Board</NavLink>
      </nav>
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <button className="add-task-button" onClick={handleAddTaskClick}>Add Task</button>
        <button className="filter-button" ref={filterButtonRef} onClick={() => setFilterPanelVisible(!filterPanelVisible)}>Filter</button>
        {filterPanelVisible && (
          <div ref={filterPanelRef}>
            <FilterPanel
              filter={filter}
              setFilter={setFilter}
              statuses={statuses}
              dueDateOptions={dueDateOptions}
              users={users}
              allTags={allTags}
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              clearStatusFilter={clearStatusFilter}
              clearDueDateFilter={clearDueDateFilter}
              clearAssignedToFilter={clearAssignedToFilter}
              closePanel={() => setFilterPanelVisible(false)}
              hideStatusFilter={true}
            />
          </div>
        )}
      </div>
      <DragDropContext onDragEnd={handleCardMove}>
        <div className="kanban-board" style={{ overflowX: 'auto' }}>
          {columns.map((column) => (
            <Droppable key={column.id} droppableId={column.id}>
              {(provided) => (
                <div
                  className="kanban-column"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <div className="kanban-column-header">
                    <h3>{column.title}</h3>
                  </div>
                  {column.tasks
                    .sort((a, b) => a.order - b.order)
                    .map((task, index) => renderCard(task, index))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>

      {taskFormVisible && (
        <AddTaskForm
          users={users}
          statuses={statuses}
          groupedTasks={groupedTasks}
          onSave={handleSaveTask}
          onCancel={closeTaskForm}
          initialTask={currentTask}
          allTags={allTags} // Ensure allTags is passed to AddTaskForm
          refreshTasks={refreshTasks} // Pass the refreshTasks function
        />
      )}
    </div>
  );
}
