import { Account } from 'views/account';
import { Users } from 'views/account/users';
import { APIKeyList } from 'views/account/apikey/list';
import { APIKeyEditor } from 'views/account/apikey/edit';
import { Emails } from 'views/account/emails';

const Routes = [
  {
    path: '/account',
    view: Account,
    layout: 'firm',
    permission: 'admin',
    title: 'Account Details'
  },
  {
    path: '/account/users',
    view: Users,
    layout: 'firm',
    permission: 'admin',
    title: 'Users'
  },
  {
    path: '/account/apikeys',
    view: APIKeyList,
    layout: 'firm',
    permission: 'developer', 
    title: 'API Keys'
  },
  {
    path: '/account/apikeys/create',
    view: APIKeyEditor,
    layout: 'firm',
    permission: 'developer', 
    title: 'API Keys'
  },
  {
    path: '/account/apikeys/edit',
    view: APIKeyEditor,
    layout: 'firm',
    permission: 'developer',
    title: 'API Keys'
  },
  {
    path: '/account/emails',
    view: Emails,
    layout: 'firm',
    permission: 'user',
    title: 'E-mail Info'
  }
]

export default Routes;