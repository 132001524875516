import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { Grid } from "@mui/material";
import { Button, Card, useAPI, ViewContext, AuthContext, Badge, HoverNav } from 'components/lib';
import moment from 'moment'
import JsonCompare from "components/JsonCompare/jsonCompare";
import styles from './styles.css'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

const RecordDetails = (props) => {
  
  const [currentEvent, setCurrentEvent] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [compareEvent, setCompareEvent] = useState(null);
  const [compareIndex, setCompareIndex] = useState(-1);
  const [eventList, setEventList] = useState([]);
  const [auditRuleStatus, setAuditRuleStatus] = useState([]);
  
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const [currentRealm, setCurrentRealm] = useState(authContext.user.appConnection);
  var record = useAPI('/api/appData/record/' + props.id)


  useEffect(() => {
    // const unsubscribe = DataHistoryService.getAllEvents(props.realmId, props.record.id).orderBy("lastUpdated", "desc").onSnapshot(onDataChange);
    // return () => unsubscribe();

    async function fetchRec() {
      //alert(props.realmId)
      let res = null;
      try {
        res = await axios({ method: 'get', url: '/api/appData/recordEvents/' + props.id, data: { org_id: currentRealm, rec_id: props.id }});
        // console.log(JSON.stringify(res))
      } catch(error) {
        // context?.notification.show(error.response, "error", false);
        return;
      }
      let recList = [];

      res.data.data.history.map((item) => {
        //let data = item.data();
        recList.push({
          id: item.id,
          operation: item.operation,
          lastUpdated: item.created_at,
          recDetails: item.rec_details,
          status: item.status,
          short_description: item.short_description,
          restore_id: item.restore_id,
          restore_to: item.restore_to
        });
      });

      setEventList(recList);

      let statusList = [];
      res.data.data.status.map((item) => {
        //let data = item.data();
        statusList.push({
          id: item.id,
          result: item.result,
          created_at: item.created_at,
          short_description: item.short_description
        });
      });

      setAuditRuleStatus(statusList);



      //res.data['2fa_required'] ? History.push(`/signin/otp?token=${res.data.token}`) : authContext.current.signin(res);
    }

    fetchRec();

  }, [props.id]);


  const setActiveEvent = (rec, index) => {
    const { id, operation, lastUpdated } = rec;

    if(("Note" === operation) || ("Delete" === operation)) {
      return;
    }

    if(index === currentIndex) {
      setCurrentEvent(null);
      setCurrentIndex(-1);
      return;
    }

    setCurrentEvent({
      id,
      operation,
      lastUpdated,
      recDetails: rec.recDetails
    });

    setCurrentIndex(index);
  };

  const setActiveCompareEvent = (rec, index) => {
    const { id, operation, lastUpdated } = rec;

    if(("Note" === operation) || ("Delete" === operation)) {
      return;
    }

    if(currentIndex === index) {
      return;
    }
    if(index === compareIndex) {
      setCompareEvent(null);
      setCompareIndex(-1);
      return;
    }
    setCompareEvent({
      id,
      operation,
      lastUpdated,
      recDetails: rec.recDetails
    });

    setCompareIndex(index);
  };




const rollbackToRec = () => {
  console.log('rollback')
    
    axios.get('/api/appData/restore_rec/' + currentRealm + '/' + props.id + '/' + currentEvent.id)
       // .then(response => this.setState({ articleId: response.data.id }));

       context.notification.show('Your data is being restored now.', 'success', true, 'toast', 'heart');
};

const rollbackToEvt = (evt, index) => {

  context.modal.show({

    title: 'Restore Record?',
    form: {},
    buttonText: 'Yes, Restore',
    url: '/api/appData/restore_rec/' + currentRealm + '/' + props.id + '/' + evt.id,
    method: 'GET',
    text: 'Are you sure you want to restore this record? ',

  }, async () => {
    context.notification.show('Your data is being restored now.', 'success', true, 'toast', 'heart');
  });

  // console.log('rollback')
  // axios.get('/api/appData/restore_rec/' + props.realmId + '/' + props.record.id + '/' + evt.id)
  //      // .then(response => this.setState({ articleId: response.data.id }));

  
};

const clearRule = (rec) => {

  context.modal.show({
    title: 'Ignore Alert',
    form: {
      id: {
        type: 'hidden',
        value: rec.id
      },
      ignore: {
        type: 'hidden',
        value: true
      },
      note: {
          label: 'Notes',
          type: 'textarea',
          value: 'Rule ignored: '
        },
      record_id: {
        type: 'hidden',
        value: props.id
      },
      account_id: {
        type: 'hidden',
        value: authContext.user.account_id
      }
    },
    buttonText: 'Ignore Alert',
    url: '/api/auditRule/clearRule',
    method: 'POST'

  }, (res) => {

    context.notification.show('This alert will now be ignored.', 'success', true);
    // callback(res);

  });

  // console.log('clearRule - ' + JSON.stringify(rec))
    
  // const details = {
  //   id: rec.id,
  //   ignore: true
  // };
  // axios.post('/api/auditRule/clearRule', details);


    // axios.post('/api/auditRule/clearRule/' + props.realmId + '/' + props.record.id + '/' + currentEvent.id)
       // .then(response => this.setState({ articleId: response.data.id }));

      //  context.notification.show('This rule will now be ignored.', 'success', true, 'toast', 'heart');
};


const deeplinkToRec = () => {
  // alert(JSON.stringify(record));
  window.open(record.data.url);
};

// const recCreateTime = moment(props.record.lastUpdated);
// const recLastUpdateTime = moment(props.record.lastUpdated);


function displayData(data, oldData, indent = 0) {
  const indentStr = '  '.repeat(indent);

  return Object.keys(data).map(key => {
    const value = data[key];
    const oldValue = oldData[key];

    if (typeof value === 'object') {
      return (
        <div key={key}>
          {indentStr}{key}:
          {Array.isArray(value) ?
            value.map((item, index) => (
              <div key={item.Id || index}>
                {displayData(item, oldValue && oldValue[index], indent + 1)}
              </div>
            )) :
            <div>{displayData(value, oldValue, indent + 1)}</div>
          }
        </div>
      );
    } else {
      const style = oldValue !== undefined && oldValue !== value ? { color: 'green' } : {};
      const oldStyle = oldValue !== undefined && oldValue !== value ? { color: 'red', textDecoration: 'line-through' } : {};
      return (
        <div style={{paddingLeft: '10px', fontSize: '16px'}} key={key}>
          {indentStr}{key}: <span style={style}>{value} </span> 
          
          {oldValue !== value &&
          <span style={oldStyle}> {oldValue}</span>
          }
        </div>
      );
    }
  });
}

  return (
    <div>
      
    {props.id ? (

      <div className="edit-form">
          <Grid container spacing={2}>
            <Grid item xs={6} md={8}>
              <Card>
              <div style={{display: 'flex', paddingBottom: 20}}>
                <div style={{paddingLeft: '10px', marginTop: '1px', marginLeft: 'auto'}}>
                
              {authContext.user.account_settings?.dev === true &&
                <HoverNav
                  useButton={true}
                  dark
                  icon='book' 
                  label={ "Restore" } 
                  
                  className='hoverNav'
                  btnClass='actionBarBtn'
                  align='right' >
                  { eventList.map((evt, index) => {
                      
                      return (
                        <Button 
                          key={ evt.id }
                          text={ evt.operation + ": " + evt.lastUpdated } 
                          action={ () => rollbackToEvt(evt, index) }

                        />
                      );
                    }
                  )}
                </HoverNav>
              }
                <Button text='Edit' small='true' className='actionBarBtn' action={(deeplinkToRec)} />
                

                {/* <Button text='Restore' small='true' className='actionBarBtn' action={(rollbackToRec)} /> */}
              </div>
            </div>


              {/* <h1>{props.record.record_type}: {props.record.name}</h1> */}
              
              <div class="section">

              <div className="form-group">
                <label class="label">
                  ID:
                </label>
                {props.id}
              </div>

              {/* <div className="form-group">
                <span class="label">
                  Created: 
                </span>
                {recCreateTime.format('lll')}
              </div>
              <div className="form-group">
                <label class="label">
                  Last Updated: 
                </label>
                {recLastUpdateTime.format('lll')}
              </div> */}
            </div>

              

            {/* <div class="section">
              <h3>Tags</h3>
              <Autocomplete
                multiple
                id="tags-filled"
                options={tags.map((option) => option.title)}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    hiddenLabel
                    placeholder=""
                    size="medium"
                  />
                )}
              />
            </div> */}


              <div className="section">
              <div className="list row">
              

              <div className="col-md-6">
              

<div className="section">
  <h3>Current Issues</h3>
  <ul className="list-group">
    
  { (auditRuleStatus &&
    auditRuleStatus.length === 0) &&
    <span>None</span>
  }

   { auditRuleStatus &&
    auditRuleStatus.map((rec, index) => {
      return( <li
        style={{height: 40, paddingLeft: 10, fontSize: '11pt'}}
        className={"list-group-item "}>
          <Badge color={rec.result === 'warning' ? 'yellow' : 'orange'} text={rec.result} /> 
          <span className="auditRuleText">{rec.short_description}</span>

          <Button
              small='true'
              text='Ignore'
              className='actionBarBtn'
              action={ () => clearRule(rec, index) }
            />
        </li>
    )}
    )}
  </ul>
</div>

{/* <div className="section">
  <h3>Notes</h3>
  <TextField
      hiddenLabel
      placeholder=""
      multiline="true"
      minRows="4"
      maxRows="10"
      fullWidth
      InputProps={{ style: { fontSize: 14 } }}
    />
    <Button text='Save' small='true' className='actionBarBtn saveBtn' />
</div> */}

<div className="section">
  <h3>Activity</h3>
  <ul className="list-group">

  <Timeline>

  {eventList &&
      eventList.map((rec, index) =>{
        var m = moment(rec.lastUpdated);

        return(
      <TimelineItem style={{marginBottom: 0}}>
        <TimelineOppositeContent style={{paddingTop: '6px'}}>
            {m.format('lll')}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary" variant="outlined" />
        </TimelineSeparator>
        <TimelineContent style={{paddingTop: '6px'}}>
          <li
            style={{height: 38, paddingLeft: 10, cursor: 'pointer', fontSize: '1.0rem'}}
              className={"list-group-item " + (index === currentIndex ? "selectedEvent" : "") + (index === compareIndex ? "compareEvent" : "")}
              onClick={() => setActiveEvent(rec, index)}
              key={rec.id}
            >
              <span style={{width: 60, display: "inline-block"}}>{rec.operation}</span>
            
              <span style={{paddingLeft: 20}}>
              { rec.status !== 'ok' &&
                <Badge color={rec.status === 'warning' ? 'yellow' : rec.status === 'deleted' ? 'blue' : 'orange'} text={rec.status} />
              }

              { rec.operation === 'Note' &&
                <span>{rec.short_description}</span>
              }
            </span>
            { currentIndex !== -1 && rec.operation !== 'Note' && rec.operation !== 'Delete' &&
                    <Button
                      small='true'
                      text='Compare'
                      textOnly='true'
                      action={ () => setActiveCompareEvent(rec, index) }
                    />
            }
            {rec.restore_to &&
            <div>
              <span style={{fontSize: '10pt'}}>Data restored to: <a href={"/app/activity/restoreDetails/" + rec.restore_id}>{moment(rec.restore_to).format('lll')}</a></span>
            </div>
            }
            </li>
        </TimelineContent>
      </TimelineItem>
        )
      })
    }

    </Timeline>

{/* 
    {eventList &&
      eventList.map((rec, index) =>{
        var m = moment(rec.lastUpdated);
      return( <li
      style={{height: 40, paddingLeft: 10, cursor: 'pointer', fontSize: '1.5rem'}}
        className={"list-group-item " + (index === currentIndex ? "selectedEvent" : "") + (index === compareIndex ? "compareEvent" : "")}
        onClick={() => setActiveEvent(rec, index)}
        key={rec.id}
      >
        <span style={{paddingRight: 10}}>
         {rec.operation}: {m.utc().format('lll')}
        </span>
      { rec.status != 'ok' &&
        <Badge color={rec.status == 'warning' ? 'yellow' : 'orange'} text={rec.status} />
      }

      { currentIndex != -1 &&
        <Button
          small='true'
          text='Compare'
          textOnly='true'
          action={ () => setActiveCompareEvent(rec, index) }
        />
}
      </li>
      )}
      )} */}
  </ul>
</div>




                
              </div>
            </div>
              </div>

              {/* <div class="section">
              <h3>Associated Records</h3>
            </div> */}

            
              
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
          {currentEvent ? (
                  
                  <div className="form-group">
                    
                    
                    {/* <div className="form-group">
                      <label>Record Action: </label>
                      {props.record.operation}
                    </div> */}
                    {/* <ReactJson 
                      src={currentEvent.recDetails}
                      enableClipboard={false}
                      displayObjectSize={false}
                      style={{
                        fontSize: '10pt'
                      }}
                    /> */}

                  <div style={{
                    fontSize: '10pt'
                  }}>
                  {
                   currentEvent.operation != 'Clear' &&
                    <>
                      <h3>Details</h3>
                      <JsonCompare showIndex={false} oldData={compareEvent ? compareEvent?.recDetails : currentEvent.recDetails} newData={currentEvent.recDetails} />
                    {/* {displayData(currentEvent.recDetails, compareEvent ? compareEvent?.recDetails : currentEvent.recDetails)} */}
                    </>
                  }
                  
                  
                  </div>
                  </div>
                ) : (
                  <div></div>
                )}
          </Grid>

        </Grid>
        {/* <button className="badge badge-danger mr-2" onClick={(rollbackToRec)}>
            Update
          </button> */}

      </div>
    ) : (
      <div>
        <br />
        <p>Please click on a Record...</p>
      </div>
    )}
  </div>
  );
};

export default RecordDetails;