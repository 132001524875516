/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header } from 'components/lib';
import ClassNames from 'classnames';
import Style from './accountList.module.scss';
import '../layout.scss'; // globals

export function AccountListLayout(props){

  // context & style
  const context = useContext(AuthContext);
  const css = ClassNames([ Style.accountList, 'with-sidebar' ]);

  return (
    <Fragment>
      <AppNav
        type='popup'
        items={[
          
          ]}
        bottomItems={[
          { label: 'Help', icon: 'help-circle', link: '/help' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={ css }>

        <Header title={ props.title } />

        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}
