import axios from 'axios';
import apps from './apps.json';

export const findApp = (name) => {
  return apps.find(app => app.short_name === name || app.name === name);
};

export const findAppByConnectionId = (id, connections) => {
  const connection = connections.find(conn => conn.id === id);
  return connection ? findApp(connection.name) : null;
};

export const findConnection = (item, connections) => {
  return connections.find(conn => conn.short_name === item.short_name);
};

export const connectApp = (short_name, accountId, data = null) => {
  switch (short_name) {
    case 'qbo':
      window.location.href = `/authUri?accountId=${accountId}`;
      break;
    case 'xero':
      window.location.href = `/xero/authUri?accountId=${accountId}`;
      break;
    case 'freshbooks':
      window.location.href = `/freshbooks/authUri?accountId=${accountId}`;
      break;
    case 'slack':
      window.location.href = `https://slack.com/oauth/v2/authorize?scope=channels%3Aread%2Cincoming-webhook%2Cchat%3Awrite&redirect_uri=https%3A%2F%2Fhindsight.ngrok.io%2Fslack%2Fcallback&client_id=2087225461440.4042097929767&state=${accountId}`;
      break;
    case 'asana':
      window.location.href = `/asana/authUri?accountId=${accountId}`;
      break;
    case 'hubspot':
      window.location.href = `/hubspot/authUri?accountId=${accountId}`;
      break;
    case 'stripe':
      connectStripe(data);
      break;
    case 'teams':
      connectTeams(data);
      break;
    case 'karbon':
      connectKarbon(data);
      break;
    case 'dynamics':
      connectDynamics(data);
      break;
    default:
      break;
  }
};

export const disconnectApp = (id, viewContext, updateConnections) => {
  viewContext.modal.show({
    title: 'Disconnect Application?',
    form: {},
    buttonText: 'Disconnect',
    url: `/api/app_connection/disconnect/${id}`,
    method: 'PATCH',
    destructive: false,
    text: 'Are you sure you want to disconnect this application?',
  }, async () => {
    const list = await axios.get('/api/app_connection');
    updateConnections(list.data);
  });
};

const connectStripe = (data) => {
  const appConnectionReq = {
    app: 'stripe',
    co_name: '',
    name: 'Stripe',
    is_sandbox: false,
    is_notification_conn: false,
    unique_id: `stripe_${data.accountId}_`,
    settings: {
      api_key: data.api_key.value
    }
  };
  axios.post('/api/app_connection', appConnectionReq);
};

const connectTeams = (data) => {
  const appConnectionReq = {
    app: 'teams',
    co_name: '',
    name: data.name,
    is_sandbox: false,
    is_notification_conn: true,
    unique_id: `teams_${data.accountId}_`,
    settings: {
      webhook_url: data.webhook_url
    }
  };
  axios.post('/api/app_connection', appConnectionReq);
};

const connectKarbon = (data) => {
  const appConnectionReq = {
    app: 'karbon',
    co_name: '',
    name: 'Karbon',
    is_sandbox: false,
    is_notification_conn: false,
    data_src_id: data.org_id.value,
    unique_id: `karbon_${data.accountId}_`,
    settings: {
      access_token: data.access_token.value,
      work_template_id: data.work_template_id.value,
      assignee_email: data.assignee_email.value
    }
  };
  axios.post('/api/app_connection', appConnectionReq);
};

const connectDynamics = (data) => {
  window.location.href = `/dynamics/authUri?accountId=${data.accountId}&tenant_id=${data.tenant_id.value}`;
};
