import React, { useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import { TaskList } from '@tiptap/extension-task-list';
import { TaskItem } from '@tiptap/extension-task-item'; // Correct import for TaskItem
import { Table } from '@tiptap/extension-table';
import { TableRow } from '@tiptap/extension-table-row'; // Correct import for TableRow
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header'; // Import TableHeader extension
import { Document } from '@tiptap/extension-document'; // Import Document extension
import { Text } from '@tiptap/extension-text'; // Import Text extension
import { Paragraph } from '@tiptap/extension-paragraph'; // Import Paragraph extension
import './Editor.css'; // Add your CSS for styling

const NotionLikeEditor = () => {
  const [content, setContent] = useState('<p>Hello! Start writing...</p>');

  const editor = useEditor({
    extensions: [
      Document, // Add Document extension
      Text, // Add Text extension
      Paragraph, // Add Paragraph extension
      TaskList,
      TaskItem,
      Table,
      TableRow,
      TableCell,
      TableHeader, // Add TableHeader extension
    ],
    content,
    onUpdate: ({ editor }) => {
      setContent(editor.getHTML()); // Update content when editor changes
    },
  });

  return (
    <div className="editor-container">
      <h2>Notion-like Editor with Checklists, Tables, and Collaboration</h2>
      <EditorContent editor={editor} />
      <div className="content-preview">
        <h3>Preview:</h3>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default NotionLikeEditor;
