import React, { Fragment, useState, useContext, useEffect } from 'react';
import { AuthContext, Card, useAPI, Button, Form } from 'components/lib';
import { NavLink } from "react-router-dom";
import { Table, ViewContext } from 'components/lib';
import Select from 'react-select';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import Style from './activity.module.scss';

export function Restore(props){

  const [recordList, setRecordList] = useState([]);
  const [dataTypeList, setDataTypeList] = useState([]);
  const [currentDataTypes, setCurrentDataTypes] = useState([]);

  const authContext = useContext(AuthContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [numSelected, setNumSelected] = useState(0);
  const [selectedRecordList, setSelectedRecordList] = useState([]);

  const [currentRealm, setCurrentRealm] = useState(authContext.user.appConnection);

  const [showRestore, setShowRestore] = useState(false);
  const context = useContext(ViewContext);
    
  // const [connections, setConnections] = useState([]);
  // const [realms, setRealms] = useState([]);
  // const list = useAPI('/api/app_connection');
  var records = useAPI('/api/appData/recordsProcessed/' + authContext.user.appConnection)
  var dataTypes = useAPI('/api/appData/dataTypes/')
  const dataSummary = useAPI('/api/appData/dataProcessedChart');
  

  // useEffect(() => {
  //   if(list?.data?.length) {
  //     //console.log('list.data.length - ' + list.data.length)
  //     setConnections(list.data);
  //   }
  //   var listRealms = [];
  //   list?.data?.map((item) => {
  //     listRealms.push({value: item.data_src_id, label: item.name})
  //   });
  //   setRealms(listRealms);

  // }, [list.data]);

  const updateRecordList =  (records) => {
    //alert(JSON.stringify(records))
    if(records?.length) {
      console.log('records.data.length - ' + records?.length)

      let recList = [];
      
      records.map(async (item) => {
        let id = item.id;
        //let data = item.data();
        recList.push({
          id: id,
          checked: false,
          type: item.record_type,
          name: item.name,
          Last_Updated: item.last_updated_at,
          //recDetails: data.recDetails
        });
      });
      setRecordList(recList);
    }
    else {
      setRecordList([]);
    }
  }

  

  useEffect(() => {
    updateRecordList(records?.data)

  }, [records.data]);

  useEffect(() => {
    if(dataTypes?.data?.length) {
      
      let recList = [];

      dataTypes.data.map((item) => {
        
        recList.push({
          value: item.name,
          label: item.name
        });
      });

      setDataTypeList(recList);

    }

  }, [dataTypes.data]);

  const selectDataTypes = (list) => {
    console.log(list)

    var types = [];
    list.map((item) => {
        types.push(item.value[0].toString());
      
    })
    console.log(types)
    setCurrentDataTypes(types)
    
  }

  //const realmId = '4620816365064851030';

  // const onDataChange = (items) => {
  //   let recList = [];

  //   items.docs.forEach((item) => {
  //     let id = item.id;
  //     let data = item.data();
  //     recList.push({
  //       id: id,
  //       name: data.name,
  //       type: data.operation,
  //       lastUpdated: data.lastUpdated,
  //       //recDetails: data.recDetails
  //     });
  //   });

  //   setRecordList(recList);
  // };

  useEffect(() => {
    
    if(currentRealm) {
      //alert(currentRealm)
    // const unsubscribe = DataHistoryService.getAll(currentRealm).orderBy("lastUpdated", "desc").onSnapshot(onDataChange);
    
    // return () => unsubscribe();

    
    }
  }, [currentRealm]);

  const selectRecord = (id) => {

    var numSelected = 0;
    var tmpRecList = [];
    recordList.map((rec) => {
      if(rec.id === id) {
        rec.checked = !rec.checked;
      }
      if(rec.checked === true) {
        tmpRecList.push(rec.id);
        numSelected = numSelected + 1;
      }
    })

    console.log(tmpRecList)
    setSelectedRecordList(oldArray => tmpRecList );
    setNumSelected(numSelected);
  }

  const setActiveRecord = (rec, index) => {
    window.location = 'details/'+rec.id;
  };
  
  const handleDateChange = (date) => {
    console.log(date.toString())
    setSelectedDate(new Date(date));
  };

  const findRecs = async () => {
    
    var dataTypes = currentDataTypes
    if(dataTypes) {
      dataTypes = 'null'
    }
    console.log('findRecs - dataTypes: ' + dataTypes)
    const res = await axios({

      url: '/api/appData/recordsProcessedSearch/' + authContext.user.appConnection + '/' + moment(selectedDate).toLocaleString() + '/' + dataTypes,
      method: 'get',
      
    })
    
    records = res.data?.data;
    updateRecordList(records)
  };

  var allDataLabel = 'Restore all data to: ' + selectedDate;
  if(currentDataTypes) {
    allDataLabel = 'Restore all ' + currentDataTypes + ' records to: ' + selectedDate?.toLocaleString();
  }
  const selectedRecLabel =  numSelected + ' selected records to: ' + selectedDate?.toLocaleString();


  return (
    <>
    <nav className='subnav'>

    <NavLink exact to='/app/activity/restore' activeClassName='active'>Data Restore</NavLink>
    <NavLink exact to='/app/activity/restoreHistory' activeClassName='active'>Restore History</NavLink>
    </nav>
    { showRestore &&
    
        <Card title='Restore Data' shadow className={ Style.feedback }>

          <Button 
            icon='x' 
            color='white' 
            className={ Style.close } 
            size={ 16 }
            action={ e => setShowRestore(false) }
          />

          <Fragment>
              <Form 
                data={{
                  allData: {
                  label: 'Select the data you would like to restore',
                  type: 'checkbox',
                  cols: 1,
                  singleSelect: true,
                  options: [selectedRecLabel, allDataLabel],
                  required: true
                },
                dtSelected: {
                  type: 'hidden',
                  value: {selectedDate}
                },
                typeSelected: {
                  type: 'hidden',
                  value: {currentDataTypes}
                },
                recsSelected: {
                  type: 'hidden',
                  value: {selectedRecordList}
                }
                }}
                method='POST'
                url='/api/appData/restore'
                updateOnChange
                buttonText='Start Data Restore'
                onChange={ e => { return false }}
                callback={ e => {

                   setShowRestore(false);
                   context.notification.show('Your data is being restored now.', 'success', true, 'toast', 'heart');

                }}
              />
          </Fragment>         
        </Card>
      }

    
      <>
      {/* <Grid cols='2'>
        <Card title={'Last Back-up'} minHeight='388px'>
        <Stat
          large = {true}
          value='6:25 PM'
          label='3/14/2023'
          // icon='chartup'
          // change='-5'
        />
        </Card>
        <Card title={'Activity'}>
          
          <Chart
            type='line'
            legend
            loading={ dataSummary.loading }
            data={ dataSummary.data }
            color={['blue', 'green', 'red']}
          />
        </Card>
      </Grid> */}

      <Card title={'Data Restore'}>
        <div style={{paddingBottom: 20}}>
          <div style={{marginTop: '16px', paddingRight: '10px', fontSize: '20px'}}>Select the data types that you would like to restore:</div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
          
          <div style={{zIndex:1000, paddingBottom: '20px', paddingTop: '8px'}}>
          <Select
              placeholder="All Data Types"
              onChange={(item) => {selectDataTypes(item)}}
              options={dataTypeList}
              menuPortalTarget={document.body}
              closeMenuOnSelect={false}
              isMulti
              styles={{
                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                control: (base) => ({...base, height: 53, minHeight: 53, marginRight: 20, marginLeft: 20 })
              }}
          />
          </div>
          

          <div style={{marginTop: '16px', paddingRight: '10px', fontSize: '20px'}}>Select the date/time that you would like to restore to:</div>
          
          <div style={{paddingLeft: '20px', paddingBottom: '20px', paddingTop: '8px'}}>
          <DateTimePicker
            showDaysOutsideCurrentMonth={true}
            showTodayButton={true}
            //maxDateTime={new moment()}
            label=""
            // value={selectedDate}
            onChange={handleDateChange}
            InputProps={{
              style: {
                color: '#707070',
              fontSize: '14px'}}}
            DialogProps={{
              style: {
                color: '#707070',
              fontSize: '14px'}}}
            renderInput={(params) => <TextField  {...params} />}
          />
          </div>
          
        

          <div style={{display: 'flex', paddingBottom: 20, width: '100%', marginTop: '30px'}}>
            <div style={{paddingLeft: '10px', marginTop: '1px'}}>
            <Button
              text='Find'
              action={ findRecs }
              params={{ id: 'btnFind' }}
              color='blue' 
            />
            </div>

            <div style={{paddingLeft: '10px', marginTop: '1px', marginLeft: 'auto', marginRight: '20px'}}>
              <Button
                text='Restore...'
                action={ e => setShowRestore(true) }
                params={{ id: 'btnRestore' }}
              />
            </div>
          </div>

        </LocalizationProvider>
        </div>
      <Table
        search
        data={ recordList }
        checkItems="type"
        //loading={ table.loading }
        hide={'id,checked'}
        hyperlink={[{
          col: 'name',
          //url: '/app/DataHistory',
          //callback: loadCompany
        }]}
        badge={{ col: 'plan', color: 'blue' }}
        actions={{ 
          hyperlink: setActiveRecord,
          edit: setActiveRecord, 
          selectRecord: selectRecord,
          //view: setActiveRecord, //'/dashboard' // will load this url + /rowID  
           
        }}
      >
      </Table>
      </Card>
      </>
    

      
    </>
  );
}



