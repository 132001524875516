/***
*
*   HOVER NAV
*   Reveals a nav when the user hovers over a hotspot
*   Items are rendered as children, revealed is achieved with CSS
*
*   PROPS
*   dark: boolean to set color (default: light)
*   label: the hotspot text
*   align: left or right
*
**********/

import React, { useState } from 'react';
import ClassNames from 'classnames';
import { Animate, Icon, Button } from 'components/lib';
import Style from './hover.module.scss';

export function HoverNav(props){

  // state
  const [open, setOpen] = useState(false);

  // style
  const css = ClassNames([

    Style.hoverNav,
    props.className,
    props.dark && Style.dark,
    props.align && Style[props.align]

  ]);

  const labelStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.align === 'right' ? 'flex-end' : 'flex-start'
  };

  return (
    <div className={ css }
      onMouseEnter={ e => setOpen(true)}
      onMouseLeave={ e => setOpen(false)}>

      { ((props.useButton === false) || (props.useButton === undefined)) &&
      <span className='label' style={ labelStyle }>
        { props.image && <img src={ props.image } alt={ props.label } style={{ width: 15, height: 15, marginRight: 5 }} />}
        { !props.image && props.icon && <Icon image={ props.icon } size={ 15 } className={ Style.icon }/>}
        { props.label }
      </span>
      }

      {props.useButton &&
        <Button className={props.btnClass} text={props.label} small='true' />
      }

      { open && props.children?.length && 
        <Animate type='slidedown' timeout={ 50 }>
        <nav className='dropdown'>
          { props.children }
        </nav>
        </Animate>
      }

    </div>
  );
}
