/***
*
*   ACCOUNT
*   Index page for account functions
*
**********/

import React, { useContext } from 'react';
import { AuthContext, Animate, Grid, Card, Icon, Link, useAPI, Loader } from 'components/lib';

export function Account(props){

  const context = useContext(AuthContext);
  const user = useAPI('/api/user');

  if (user.loading)
    return <Loader />

  return (
    <Animate>
      {/* Profile Section */}
      

      { (context.permission?.owner || context.permission?.admin) &&
      <>
      <h2>Account Settings</h2>
      <Grid cols='4'>

          <Card>
            <Icon image='users'/>
            <h2>Users</h2>
            <div>Invite users to your account</div>
            <Link url='/account/users'>Manage Users</Link>
          </Card>
        
          <Card>
            <Icon image='mail'/>
            <h2>E-mails</h2>
            <div>Get a summary of activity</div>
            <Link url='/account/emails'>Manage E-mails</Link>
          </Card>

          <Card>
            <Icon image='settings'/>
            <h2>API Keys</h2>
            <div>Manage your API keys</div>
            <Link url='/account/apikeys'>Manage</Link>
          </Card>
        
      </Grid>
      </> 
      }
      
    </Animate>
  )
}