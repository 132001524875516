/***
*
*   ACCOUNT NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import { AuthContext } from 'components/lib';
import './nav.scss';

export default function AccountNav(props){

  const context = useContext(AuthContext);

  return(
    <nav className='subnav'>

      <NavLink exact to='/account/users' activeClassName='active'>Users</NavLink>
      <NavLink exact to='/account/emails' activeClassName='active'>E-mail Digest</NavLink>
      <NavLink exact to='/account/apikeys' activeClassName='active'>API Keys</NavLink>

    
    </nav>
  );
}
