/***
*
*   PRICING
*   Modify the PricePlans component with your own features
*
**********/

import { Animate, Row, PricePlans } from 'components/lib';

export function Pricing(props){

  // useEffect(() => {
  //   const script = document.createElement('script');
  
  //   script.src = "https://js.stripe.com/v3/pricing-table.js";
  //   script.async = true;
  
  //   document.body.appendChild(script);
  
  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  return (
    <Animate type='pop'>
      <Row color='tint' align='center' title='Businesses are easier to run and have less overhead with LedgerView'>
        Choose the monthly plan, or receive 2 months free from the annual plan. 
        <br/>
        Enjoy a <strong>FREE 14 day trial</strong>.
        <br /><br />

        <PricePlans />

        Manage more than 10 companies? <a href='mailto:contact@ledgerview.app'>Contact Us</a>
      </Row>

    </Animate>
  )
}
