import React, { Fragment, useState, useContext, useEffect } from 'react';
import { AuthContext, Card, useAPI, Button, Form } from 'components/lib';
import { NavLink } from "react-router-dom";
import { Table, ViewContext } from 'components/lib';
import Select from 'react-select';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import Style from './activity.module.scss';
import { Accordion, AccordionTab } from 'primereact/accordion';

import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import "@webdatarocks/webdatarocks/webdatarocks.css";
import PivotUI from './Pivot';

// import TableRenderers from 'react-pivottable/TableRenderers';
// import Plot from 'react-plotly.js';
// import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

// create Plotly renderers via dependency injection
// const PlotlyRenderers = createPlotlyRenderers(Plot);

// see documentation for supported input formats
const data = [['attribute', 'attribute2'], ['value1', 'value2']];

export function Pivot(props){

  const [recordList, setRecordList] = useState([]);
  const [dataTypeList, setDataTypeList] = useState([]);
  const [currentDataType, setCurrentDataType] = useState(null);

  const authContext = useContext(AuthContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [pivotState, setPivotState] = useState({});
  
  const context = useContext(ViewContext);
    
  // const [connections, setConnections] = useState([]);
  // const [realms, setRealms] = useState([]);
  // const list = useAPI('/api/app_connection');
  var records = useAPI('/api/appData/recordsProcessed/' + authContext.user.appConnection)
  var dataTypes = useAPI('/api/appData/dataTypes/')


  const updateRecordList = (records) => {
    //alert(JSON.stringify(records))
    if(records?.length) {
      console.log('records.data.length - ' + records?.length)

      let recList = [];

      records.map((item) => {
        let id = item.id;
        //let data = item.data();
        recList.push({
          id: id,
          checked: false,
          type: item.record_type,
          name: item.name,
          Last_Updated: item.last_updated_at,
          url: item.url
          //recDetails: data.recDetails
        });
      });

      setRecordList(recList);

    }
    else {
      setRecordList([]);
    }
  }

  useEffect(() => {
    updateRecordList(records?.data)

  }, [records.data]);

  useEffect(() => {
    if(dataTypes?.data?.length) {
      
      let recList = [];
      recList.push({
        value: 'null',
        label: 'All Data Types'
      });

      dataTypes.data.map((item) => {
       
        recList.push({
          value: item.name,
          label: item.name
        });
      });

      setDataTypeList(recList);

    }

  }, [dataTypes.data]);

  const selectDataType = (val) => {
    setCurrentDataType(val)
    
  }


  // const selectRecord = (id) => {

  //   var numSelected = 0;
  //   recordList.map((rec) => {
  //     if(rec.id === id) {
  //       rec.checked = !rec.checked;
  //     }
  //     if(rec.checked === true) {
  //       numSelected = numSelected + 1;
  //     }
  //   })
  //   setSelectedRecordList(oldArray => [recordList] );
  //   setNumSelected(numSelected);
  // }

  const setActiveRecord = (rec, index) => {
    window.location = 'details/'+rec.id;
  };
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const findRecs = async () => {
    const res = await axios({

      url: '/api/appData/recordsProcessedSearch/' + authContext.user.appConnection + '/' + moment(selectedDate).format('YYYY-MM-DD hh:mm:A') + '/' + currentDataType,
      method: 'get',
      
    })
    
    records = res.data?.data;
    updateRecordList(records)
  };

  return (
    <>
    {/* <nav className='subnav'>

    <NavLink exact to='/app/activity/index' activeClassName='active'>Activity</NavLink>
    <NavLink exact to='/app/activity/restore' activeClassName='active'>Data Restore</NavLink>
    <NavLink exact to='/app/activity/restoreHistory' activeClassName='active'>Restore History</NavLink>
    </nav> */}
    

    
      <>
      

      <Card>
        <div style={{display: 'flex', paddingBottom: 10}}>
          <div style={{marginTop: '16px', paddingRight: '10px'}}>Filter:</div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            showDaysOutsideCurrentMonth={true}
            showTodayButton={true}
            //maxDateTime={new moment()}
            label="Last Updated Before:"
            value={selectedDate}
            onChange={handleDateChange}
            InputProps={{
              style: {
                color: '#707070',
              fontSize: '14px'}}}
            DialogProps={{
              style: {
                color: '#707070',
              fontSize: '14px'}}}
            renderInput={(params) => <TextField  {...params} />}
          />
          <div style={{zIndex:1000, width: '200px'}}>
          <Select
              placeholder="All Data Types"
              onChange={(item) => {selectDataType(item.value)}}
              options={dataTypeList}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{
                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                control: (base) => ({...base, height: 53, minHeight: 53, marginRight: 20, marginLeft: 20 })
              }}
          />
          </div>
        

          <div style={{paddingLeft: '10px', marginTop: '1px'}}>
          <Button
            text='Find'
            action={ findRecs }
            params={{ id: 'btnFind' }}
            color='blue' 
          />
          </div>


        </LocalizationProvider>
        </div>
      
        <Accordion activeIndex={1}>
                <AccordionTab header="Results">
        <Table
        search
        data={ recordList }
        // checkItems="type"
        //loading={ table.loading }
        hide={'id,checked,url'}
        hyperlink={[{
          col: 'name',
          //url: '/app/DataHistory',
          //callback: loadCompany
        }]}
        badge={{ col: 'plan', color: 'blue' }}
        actions={{ 
          hyperlink: setActiveRecord,
          edit: setActiveRecord, 
          // selectRecord: selectRecord,
          //view: setActiveRecord, //'/dashboard' // will load this url + /rowID  
           
        }}
      >
      </Table>
      </AccordionTab>
      </Accordion>

      </Card>

<div style={{paddingTop: 20}}>
      {/* <PivotTableUI
        data={data}
        onChange={newState => setPivotState(newState)}
        {...pivotState}
      /> */}
      <PivotUI
         toolbar={true}
         width="100%"
         height="800"
        //  report="https://cdn.webdatarocks.com/reports/report.json"
        />
      </div>
      </>
    

    </>
  );
}



