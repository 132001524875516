import { Lock, LockOpen, TextFields, BarChart, Edit } from "@mui/icons-material"; // Add BarChart and Edit icons
import { Box, Button, Stack, Typography, Dialog, DialogContent, CircularProgress } from "@mui/material"; // Add Dialog, DialogContent, and CircularProgress
import { useCallback, useRef, useState, useContext } from "react";
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  RichTextReadOnly,
  TableBubbleMenu,
  insertImages,
} from "mui-tiptap";
import EditorMenuControls from "./EditorMenuControls";
import useExtensions from "./useExtensions";
// import html2pdf from "html2pdf.js"; // Add this import
import { ChartSettingsForm } from "./ChartSettingsForm"; // Import ChartSettingsForm
import {Chart} from "chart.js";
import axios from 'axios'; // Add this import
import { AuthContext } from 'components/lib';


function fileListToImageFiles(fileList) {
  return Array.from(fileList).filter((file) => {
    const mimeType = (file.type || "").toLowerCase();
    return mimeType.startsWith("image/");
  });
}

export default function Editor({ content }) {
  const authContext = useContext(AuthContext);
  const extensions = useExtensions({
    placeholder: "Add your own content here...",
  });
  const rteRef = useRef(null);
  const [isEditable, setIsEditable] = useState(true);
  const [showMenuBar, setShowMenuBar] = useState(true);
  const [showChartSettings, setShowChartSettings] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add this state
  const [hoveredChart, setHoveredChart] = useState(null); // Add this state
  const [chartSettings, setChartSettings] = useState(null); // Add this state
  const [chartDataList, setChartDataList] = useState([]); // Add this state

  const handleNewImageFiles = useCallback(
    (files, insertPosition) => {
      if (!rteRef.current?.editor) {
        return;
      }

      const attributesForImageFiles = files.map((file) => ({
        src: URL.createObjectURL(file),
        alt: file.name,
      }));

      insertImages({
        images: attributesForImageFiles,
        editor: rteRef.current.editor,
        insertPosition,
      });
    },
    [],
  );

  const handleDrop = useCallback(
    (view, event) => {
      if (!(event instanceof DragEvent) || !event.dataTransfer) {
        return false;
      }

      const imageFiles = fileListToImageFiles(event.dataTransfer.files);
      if (imageFiles.length > 0) {
        const insertPosition = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })?.pos;

        handleNewImageFiles(imageFiles, insertPosition);

        event.preventDefault();
        return true;
      }

      return false;
    },
    [handleNewImageFiles],
  );

  const handlePaste = useCallback(
    (_view, event) => {
      if (!event.clipboardData) {
        return false;
      }

      const pastedImageFiles = fileListToImageFiles(event.clipboardData.files);
      if (pastedImageFiles.length > 0) {
        handleNewImageFiles(pastedImageFiles);
        return true;
      }

      return false;
    },
    [handleNewImageFiles],
  );

  const [submittedContent, setSubmittedContent] = useState("");

  const handleSaveAsPDF = () => {
    const element = document.createElement("div");
    element.innerHTML = rteRef.current?.editor?.getHTML() ?? "";
    const opt = {
      margin: 1,
      filename: 'editor-content.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    alert("pdf creation is disabled for now");
    // html2pdf().from(element).set(opt).save();
  };

  const handleOpenChartSettings = () => {
    setShowChartSettings(true);
  };

  const getPeriodDates = (period) => {
    const now = new Date();
    let start_date, end_date;

    switch (period) {
      case 'This Month':
        start_date = new Date(now.getFullYear(), now.getMonth(), 1);
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'Last Month':
        start_date = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end_date = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'This Quarter':
        const currentQuarter = Math.floor((now.getMonth() + 3) / 3);
        start_date = new Date(now.getFullYear(), (currentQuarter - 1) * 3, 1);
        end_date = new Date(now.getFullYear(), currentQuarter * 3, 0);
        break;
      case 'Last Quarter':
        const lastQuarter = Math.floor((now.getMonth() + 3) / 3) - 1;
        start_date = new Date(now.getFullYear(), (lastQuarter - 1) * 3, 1);
        end_date = new Date(now.getFullYear(), lastQuarter * 3, 0);
        break;
      case 'This Year':
        start_date = new Date(now.getFullYear(), 0, 1);
        end_date = new Date(now.getFullYear(), 11, 31);
        break;
      case 'Last 12 Months':
        start_date = new Date(now.getFullYear(), now.getMonth() - 11, 0);
        console.log('start_date: ' + start_date)
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'Last 3 Years':
        start_date = new Date(now.getFullYear() - 3, 11, 31);
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'Last 5 Years':
        start_date = new Date(now.getFullYear() - 5, 11, 31);
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      default:
        // start_date = customStartDate;
        // end_date = customEndDate;
        break;
    }

    return { start_date, end_date };
  };
  const fetchChartData = async (chartData) => {
    const { start_date, end_date } = getPeriodDates("Last 12 Months");
    let url = `/api/appData/reportData/${authContext.user.app_connection_id}?dataType=${chartData.queryOptions.dataType}&dataGroups=${JSON.stringify(chartData?.queryOptions?.dataGroups !== undefined ? chartData.queryOptions.dataGroups : [])}`;
    if (start_date && end_date) {
      url += `&start_date=${start_date.toISOString()}&end_date=${end_date.toISOString()}`;
    }
    if (chartData.queryOptions.dataGroups[0].rowLimit) {
      url += `&rowLimit=${chartData.queryOptions.dataGroups[0].rowLimit}`;
    }
    console.log(url);
    const response = await axios.get(url);
    return response.data.data;
  };

  const handleSaveChartSettings = async (chartData) => {
    console.log("Chart Data:", chartData);
    if (!rteRef.current?.editor) {
      return;
    }

    setIsLoading(true); // Set loading to true

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = 400;
    canvas.height = 300;

    const chartDataFromAPI = await fetchChartData(chartData);
    const chartId = `chart-${Date.now()}`; // Generate a unique id for the chart

    new Chart(ctx, {
      type: chartData.chartType || "bar",
      data: chartDataFromAPI,
      options: {
        "scales": {
            "xAxes": [
                {
                    "gridLines": {
                        "drawOnChartArea": false,
                        "color": "rgba(0, 0, 0, 0)",
                        "zeroLineColor": "transparent"
                    },
                    "ticks": {
                        "fontFamily": "Source Sans Pro",
                        "fontSize": 10,
                        "maxRotation": 90
                    }
                }
            ],
            "yAxes": [
                {
                    "gridLines": {
                        "drawOnChartArea": false,
                        "color": "rgba(0, 0, 0, 0)",
                        "zeroLineColor": "transparent"
                    },
                    "ticks": {
                        "fontFamily": "Source Sans Pro",
                        "fontSize:": 10,
                        "beginAtZero": true
                    }
                }
            ]
        },
        "legend": {
            "display": chartData.queryOptions.showLegend,
            "position": 'bottom'
        },
        "defaultFontSize": 11,
        "maintainAspectRatio": true,
        "responsive": false,
        "tooltips": {
            "backgroundColor": "#404958",
            "titleFontSize": 12,
            "titleMarginBottom": 6,
            "xPadding": 15,
            "yPadding": 10,
            "enabled": true,
            "displayColors": false,
            "callbacks": {}
        },
        "title": {
            "text": chartData.chartTitle,
            "display": true,
        }
    }
      
    });

    setTimeout(() => {
      const img = canvas.toDataURL("image/png");
      const imgTag = `<img src="${img}" id="${chartId}" alt="${chartId}" width="400" height="300" />`;

      rteRef.current.editor
        .chain()
        .focus()
        .setImage({ src: img, id: chartId, alt: chartId, width: 400, height: 300 })
        .run();

      setChartDataList((prevList) => [...prevList, { id: chartId, data: chartData }]); // Store chart data with id
      setShowChartSettings(false);
      setIsLoading(false); // Set loading to false
    }, 1500);
  };

  const handleCancelChartSettings = () => {
    setShowChartSettings(false);
  };

  const handleMouseOverChart = (event) => {
    const target = event.target;
    if (target.tagName === "IMG" && target.alt.startsWith("chart")) {
      setHoveredChart(target);
    } else {
      setHoveredChart(null);
    }
  };

  const handleEditChart = () => {
    if (hoveredChart) {
      const chartId = hoveredChart.alt;
      const chartData = chartDataList.find(chart => chart.id === chartId)?.data;
      console.log("Edit Chart Data:", chartData);
      console.log("Edit Chart ID:", chartId);
      setChartSettings(chartData);
      setShowChartSettings(true);
    }
  };

  return (
    <>
      <Box
        onMouseOver={(event) => {
            if (event.target.tagName === "IMG" && event.target.alt.startsWith("chart")) {
            handleMouseOverChart(event);
          }
        }} // Add this event handler
        sx={{
          maxHeight: '100vh', // Set max height to the height of the screen
          overflow: 'auto', // Make the editor scrollable
          "& .ProseMirror": {
            "& h1, & h2, & h3, & h4, & h5, & h6": {
              scrollMarginTop: showMenuBar ? 50 : 0,
            },
          },
        }}
      >
        <RichTextEditor
          ref={rteRef}
          extensions={extensions}
          content={content}
          editable={isEditable}
          editorProps={{
            handleDrop: handleDrop,
            handlePaste: handlePaste,
          }}
          renderControls={() => (
            <>
              <EditorMenuControls
                onOpenChartSettings={handleOpenChartSettings}
                onSaveAsPDF={handleSaveAsPDF}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
              />
            </>
          )}
          RichTextFieldProps={{
            variant: "outlined",
            MenuBarProps: {
              hide: !showMenuBar,
            },
            footer: (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  borderTopStyle: "solid",
                  borderTopWidth: 1,
                  borderTopColor: (theme) => theme.palette.divider,
                  py: 1,
                  px: 1.5,
                }}
              >
              </Stack>
            ),
          }}
        >
          {() => (
            <>
              <LinkBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
        </RichTextEditor>
      </Box>

      {hoveredChart && (
        <Edit
          style={{
            position: "absolute",
            top: hoveredChart.getBoundingClientRect().top + window.scrollY,
            left: hoveredChart.getBoundingClientRect().left + window.scrollX + hoveredChart.width - 24, // Adjust position to upper right corner
            cursor: "pointer",
            zIndex: 1001,
            backgroundColor: "rgba(255, 255, 255, 0.7)", // Add background color to make it more visible
            borderRadius: "50%", // Make it circular
            padding: "4px" // Add some padding
          }}
          onClick={handleEditChart}
        />
      )}

      {showChartSettings && (
        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.9)", zIndex: 1000 }}>
          {isLoading ? (
            <CircularProgress style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          ) : (
            <ChartSettingsForm
              item={chartSettings || {}} // Pass chart settings to the form
              onSave={handleSaveChartSettings}
              onCancel={handleCancelChartSettings}
            />
          )}
        </div>
      )}

    </>
  );
}
