import React, { useState, useEffect } from 'react';
import { CheckCircle, ChevronLeft, ChevronRight, ExternalLink, LogIn, Users, Check, RefreshCw, X } from 'lucide-react';
import './ImportWizard.css';
import apps from '../app/apps.json'; // Import the JSON data
import axios from 'axios';

// Filtered app sources data
const APP_SOURCES = apps.filter(app => app.allow_client_import === true);


const ClientImportWizard = ( {onClose}) => {
  // Wizard state
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedApp, setSelectedApp] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [importComplete, setImportComplete] = useState(false);
  const [importStats, setImportStats] = useState({ total: 0, success: 0, failed: 0 });
  const [oauthData, setOauthData] = useState(null);
  
  // Check for stored wizard state on component mount (returning from OAuth)
  useEffect(() => {
    const storedState = localStorage.getItem('wizardState');
    if (storedState) {
      try {
        const state = JSON.parse(storedState);
        // We don't restore immediately to avoid conflicts with
        // the message event handler in handleOAuth
        localStorage.removeItem('wizardState');
      } catch (error) {
        console.error('Error parsing stored wizard state:', error);
        localStorage.removeItem('wizardState');
      }
    }

    // Set up message listener for OAuth success
    const handleMessage = (event) => {
    //   console.log('Message received:', event.data);
      if (event.data.type === 'oauth_success') {
        // console.log('OAuth success:', event.data);
        setOauthData(event.data.data);
        setIsAuthenticated(true);
        setIsLoading(false);
        setCurrentStep(3);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // Handle app selection
  const handleAppSelect = (app) => {
    setSelectedApp(app);
  };

  // OAuth process using popup window
  const handleOAuth = () => {
    setIsLoading(true);
    
    // Store current wizard state in localStorage
    localStorage.setItem('wizardState', JSON.stringify({
      currentStep: currentStep,
      selectedApp: selectedApp,
      // Add any other state you want to preserve
    }));
    
    // Open OAuth in a popup window
    const width = 600;
    const height = 700;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    
    const popup = window.open(
      selectedApp.oauthUrl,
      'oauth',
      `width=${width},height=${height},left=${left},top=${top}`
    );
    
    // // Set up message listener for when OAuth completes
    // window.addEventListener('message', (event) => {
    //   // Allow messages from any origin
    //   console.log('Message received:', event.data);
    //   if (event.data.type === 'oauth_success') {
    //     console.log('OAuth success:', event.data);
    //     if (popup && !popup.closed) {
    //       popup.close();
    //     }
    //     console.log('OAuth successful:', event.data.data);
    //     setOauthData(event.data.data);
    //     setIsAuthenticated(true);
    //     setIsLoading(false);
    //     setCurrentStep(3);
    //   }
    // }, { once: true });
    
    // Fallback for popup closure without completing OAuth
    const checkPopupClosed = setInterval(() => {
      if (popup && popup.closed) {
        clearInterval(checkPopupClosed);
        setIsLoading(false);
      }
    }, 500);
    
    // For demo purposes, simulate OAuth completion after 3 seconds
    // In a real app, this would come from the actual OAuth callback
    // setTimeout(() => {
    //     window.postMessage(
    //       { type: 'oauth_success', data: { accessToken: 'mock-token-123' } }, 
    //       window.location.origin
    //     );
    //   }, 3000);

  };

  // Fetch clients from the selected app
  const fetchClients = () => {
    setIsLoading(true);

    const apiEndpoint = selectedApp.client_list_api;
    const authToken = oauthData?.accessToken;

    axios.get(apiEndpoint)
    .then(response => {
        console.log('Fetched clients:', response.data);
      setClients(response.data);
      setIsLoading(false);
      setCurrentStep(4);
    })
    .catch(error => {
      console.error('Error fetching clients:', error);
      setIsLoading(false);
    });
  };

  // Handle client selection
  const toggleClientSelection = (clientId) => {
    if (selectedClients.includes(clientId)) {
      setSelectedClients(selectedClients.filter(id => id !== clientId));
    } else {
      setSelectedClients([...selectedClients, clientId]);
    }
  };

  // Toggle all clients
  const toggleAllClients = () => {
    if (selectedClients.length === clients.length) {
      setSelectedClients([]);
    } else {
      setSelectedClients(clients.map(client => client.id));
    }
  };

  // Import selected clients
  const importClients = () => {
    setIsLoading(true);

    const apiEndpoint = selectedApp.import_client_url;
    const authToken = oauthData?.accessToken;

    axios.post(apiEndpoint, { clients: selectedClients })
    .then(response => {
      const stats = response.data;
      setImportStats(stats);
      setImportComplete(true);
      setIsLoading(false);
      setCurrentStep(5);
    })
    .catch(error => {
      console.error('Error importing clients:', error);
      setIsLoading(false);
    });
  };

  // Reset the wizard
  const resetWizard = () => {
    setCurrentStep(1);
    setSelectedApp(null);
    setIsAuthenticated(false);
    setClients([]);
    setSelectedClients([]);
    setImportComplete(false);
    setImportStats({ total: 0, success: 0, failed: 0 });
    onClose(); // Close the wizard
  };

  // Render app selection step
  const renderAppSelection = () => (
    <div className="section-content">
      <h2 className="section-title">Select Source Application</h2>
      <div className="app-grid">
        {APP_SOURCES.map(app => (
          <div 
            key={app.id}
            className={`app-card ${selectedApp?.id === app.id ? 'app-card-selected' : ''}`}
            onClick={() => handleAppSelect(app)}
          >
            <div className="app-info">
              <img src={app.icon} alt={app.name} className="app-icon" />
              <div className="app-name">{app.name}</div>
              {selectedApp?.id === app.id && (
                <CheckCircle className="app-check" size={20} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  // Render OAuth step
  const renderOAuth = () => (
    <div className="section-content">
      <h2 className="section-title">Connect to {selectedApp?.name}</h2>
      <div className="info-card">
        <img src={selectedApp?.icon} alt={selectedApp?.name} className="app-icon" />
        <h3 className="card-title">{selectedApp?.name}</h3>
        <p className="card-description">
          You need to authorize access to your {selectedApp?.name} account to import clients.
        </p>
        <button
          className="btn btn-primary"
          onClick={handleOAuth}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <RefreshCw className="btn-icon spinner" size={16} />
              Opening {selectedApp?.name} authorization...
            </>
          ) : (
            <>
              <ExternalLink className="btn-icon" size={16} />
              Connect to {selectedApp?.name}
            </>
          )}
        </button>
        {isLoading && (
          <div className="oauth-helper-text">
            A popup window has opened. Please complete the authorization there.
            <br />
            If you don't see the popup, please check if it was blocked by your browser.
          </div>
        )}
      </div>
    </div>
  );

  // Render client fetching step
  const renderClientFetch = () => (
    <div className="section-content">
      <h2 className="section-title">Fetch Clients from {selectedApp?.name}</h2>
      <div className="info-card">
        <div className="info-icon info-icon-success">
          <CheckCircle size={48} />
        </div>
        <h3 className="card-title">Connected to {selectedApp?.name}</h3>
        <p className="card-description">
          Your account is successfully connected. Click the button below to fetch your clients.
        </p>
        
        <button
          className="btn btn-primary"
          onClick={fetchClients}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <RefreshCw className="btn-icon spinner" size={16} />
              Fetching clients...
            </>
          ) : (
            <>
              <Users className="btn-icon" size={16} />
              Fetch Clients
            </>
          )}
        </button>
      </div>
    </div>
  );

  // Render client selection step
  const renderClientSelection = () => (
    <div className="section-content">
      <div className="selection-counter">
        <h2 className="section-title">Select Clients to Import</h2>
        <div className="selection-count">
          Selected {selectedClients.length} of {clients === undefined ? '0' : clients.length} clients
        </div>
      </div>
      
      <div className="table-container">
        <table className="table">
          <thead className="table-header">
            <tr>
              <th>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedClients.length === clients.length && clients.length > 0}
                  onChange={toggleAllClients}
                />
              </th>
              {clients.length > 0 && Object.keys(clients[0]).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr 
                key={client.id}
                className={`table-row ${selectedClients.includes(client.id) ? 'table-row-selected' : ''}`}
                onClick={() => toggleClientSelection(client.id)}
              >
                <td className="table-cell">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={selectedClients.includes(client.id)}
                    onChange={() => {}} // Controlled by the row click
                  />
                </td>
                {Object.keys(client).map((key) => (
                  <td key={key} className="table-cell">
                    <div className="client-detail">{client[key]}</div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  // Render import confirmation step
  const renderImportConfirmation = () => (
    <div className="section-content">
      <h2 className="section-title">Import Complete</h2>
      <div className="info-card">
        <div className="info-icon info-icon-success">
          <CheckCircle size={48} />
        </div>
        <h3 className="card-title">Client Import Summary</h3>
        
        <div className="stats-container">
          <div className="stat-item">
            <p className="stat-value stat-value-total">{importStats.total}</p>
            <p className="stat-label">Total</p>
          </div>
          <div className="stat-item">
            <p className="stat-value stat-value-success">{importStats.success}</p>
            <p className="stat-label">Successful</p>
          </div>
          <div className="stat-item">
            <p className="stat-value stat-value-failed">{importStats.failed}</p>
            <p className="stat-label">Failed</p>
          </div>
        </div>
        
        <button
          className="btn btn-primary"
          onClick={resetWizard}
        >
          Finish
        </button>
      </div>
    </div>
  );

  // Render progress steps
  const renderProgressSteps = () => {
    const steps = [
      { id: 1, name: 'Select Source' },
      { id: 2, name: 'Connect' },
      { id: 3, name: 'Fetch Clients' },
      { id: 4, name: 'Select Clients' },
      { id: 5, name: 'Complete' },
    ];

    return (
      <div className="progress-container" style={{ display: 'flex', justifyContent: 'center' }}>
        <nav>
          <ol className="progress-list" style={{ listStyleType: 'none' }}>
            {steps.map((step, stepIdx) => (
              <li key={step.name} className="progress-step">
                {stepIdx !== steps.length && (
                  <div className={`progress-line ${currentStep > step.id ? 'progress-line-active' : 'progress-line-inactive'}`} />
                )}
                <div>
                  <span 
                    className={
                      currentStep > step.id 
                        ? 'progress-circle progress-circle-active' 
                        : currentStep === step.id 
                          ? 'progress-circle progress-circle-current' 
                          : 'progress-circle progress-circle-inactive'
                    }
                  >
                    {currentStep > step.id ? (
                      <Check size={16} />
                    ) : (
                      <span>{step.id}</span>
                    )}
                  </span>
                  <span className="progress-step-label">
                    {step.name}
                  </span>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    );
  };

  // Render wizard navigation
  const renderNavigation = () => (
    <div className="navigation">
      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => setCurrentStep(currentStep - 1)}
        disabled={currentStep === 1 || isLoading}
      >
        <ChevronLeft className="btn-icon" size={16} />
        Back
      </button>
      
      {currentStep === 4 && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={importClients}
          disabled={selectedClients.length === 0 || isLoading}
        >
          {isLoading ? (
            <>
              <RefreshCw className="btn-icon spinner" size={16} />
              Importing...
            </>
          ) : (
            <>
              <LogIn className="btn-icon" size={16} />
              Import {selectedClients.length} Clients
            </>
          )}
        </button>
      )}
      
      {currentStep < 4 && currentStep !== 2 && currentStep !== 3 && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setCurrentStep(currentStep + 1)}
          disabled={currentStep === 1 && !selectedApp}
        >
          Next
          <ChevronRight className="btn-icon-right" size={16} />
        </button>
      )}
    </div>
  );

  return (
    <div className="wizard-container">
      <div className="wizard-header">
        <h1 className="wizard-title">Client Import Wizard</h1>
        <button className="close-button-wiz" onClick={onClose}>
          <X size={32} />
        </button>
      </div>
      
      {renderProgressSteps()}
      
      {currentStep === 1 && renderAppSelection()}
      {currentStep === 2 && renderOAuth()}
      {currentStep === 3 && renderClientFetch()}
      {currentStep === 4 && renderClientSelection()}
      {currentStep === 5 && renderImportConfirmation()}
      
      {currentStep < 5 && renderNavigation()}
    </div>
  );
};

export default ClientImportWizard;