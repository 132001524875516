import React, { useState, useEffect, useRef } from 'react';
import { Editor } from 'primereact/editor';
import './CommentsPanel.css';

export function MessagesPanel({ selectedTask, onClose, onSendComment }) {
  const [newComment, setNewComment] = useState('');
  const chatBodyRef = useRef(null);

  const handleSendComment = () => {
    if (newComment.trim() !== '') {
      onSendComment(newComment);
      setNewComment('');
    }
  };

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [selectedTask.messagesList]);

  const toolbarOptions = (
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
      <select className="ql-size">
        <option value="small" />
        <option />
        <option value="large" />
        <option value="huge" />
      </select>
      <select className="ql-color" />
      <select className="ql-background" />
      <select className="ql-font" />
      <button className="ql-clean" />
    </span>
  );

  return (
    <div className="chat-panel">
      <div className="chat-header">
        <h4>Messages for {selectedTask.title}</h4>
        <button onClick={onClose} className="close-button">X</button>
      </div>
      <div className="chat-body" ref={chatBodyRef}>
        {selectedTask.messagesList && selectedTask.messagesList
          .sort((a, b) => new Date(a.time) - new Date(b.time))
          .map((message, index) => (
            <div key={index} className={`chat-message ${message.user === 'Current User' ? 'chat-message-right' : 'chat-message-left'}`}>
              <div className="chat-message-header">
                <span className="chat-user">{message.user}</span>
                <span className="chat-time">{new Date(message.time).toLocaleString()}</span>
              </div>
              <div className="chat-message-body" dangerouslySetInnerHTML={{ __html: message.message }} />
            </div>
          ))}
      </div>
      <div className="chat-footer">
        <Editor style={{ height: '100px' }} value={newComment} onTextChange={(e) => setNewComment(e.htmlValue)} placeholder="Type a message..." headerTemplate={toolbarOptions} />
        <button onClick={handleSendComment}>Send</button>
      </div>
    </div>
  );
}
