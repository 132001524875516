import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext, useAPI } from 'components/lib';
import { NavLink } from "react-router-dom";
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import FeatherIcon from 'feather-icons-react';
import { ChartSettingsForm } from './ChartSettingsForm';
import { dataPeriods, chartOptions, defaultCharts, data_vals, group_by, record_type, aggregationOptions, datetimeGroupByOptions } from './data';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import axios from 'axios';
import './Report.css'; // Add this line
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Menu } from 'primereact/menu';
import { Bar, Line, Pie, Doughnut } from 'react-chartjs-2'; // Import Chart.js components
import "chartjs-plugin-annotation";


function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function handlePageDragEnd(result, pages, setPages, setCurrentPageIndex) {
  if (!result.destination) return;
  const updatedPages = reorder(pages, result.source.index, result.destination.index);
  setPages(updatedPages);
  setCurrentPageIndex(result.destination.index);
}

export function ReportDashboard({ charts, onSaveChart, showChartSettingsForm, hideAddChartButton }) {
  const authContext = useContext(AuthContext);

  // Initialize state with the charts passed from AiCanvas
  const [pages, setPages] = useState(defaultCharts);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [modalItem, setModalItem] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [newChartPanelVisible, setNewChartPanelVisible] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("Last 12 Months");
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [draggingChartId, setDraggingChartId] = useState(null);
  const [editingTitle, setEditingTitle] = useState(false);
  const [activeChartMenu, setActiveChartMenu] = useState(null);
  const [hoveredChartId, setHoveredChartId] = useState(null);
  const [hoveredSectionIndex, setHoveredSectionIndex] = useState(null); // Track hovered section index
  const [menuVisible, setMenuVisible] = useState(false);
  const [chartId, setChartId] = useState(null); // Add state for chartId
  const [chartData, setChartData] = useState([]); // State for chart data
  const [initialLoad, setInitialLoad] = useState(true); // Track initial load
  const menu = useRef(null);

  // Fetch data for all charts when the component mounts
  useEffect(() => {
    // if (pages.length === 0 || (pages.length === 1 && pages[0].charts.length === 0)) {
    //   setPages([{ name: 'Page 1', title: 'Page 1', charts: defaultCharts }]);
    // }
  }, []);

  useEffect(() => {
    if (!initialLoad) {
      const fetchAllChartData = async (index) => {
        const updatedCharts = await Promise.all(
          pages[index].charts.map(async (item) => {
            const updatedItemWithData = await fetchChartData(item);
            return updatedItemWithData;
          })
        );
        const updatedChartData = [...chartData];
        updatedChartData[index] = updatedCharts;
        setChartData(updatedChartData);
      };
      console.log('currentPageIndex - ' + currentPageIndex === undefined ? -1 : currentPageIndex);
      var index = currentPageIndex;
      if((currentPageIndex >= pages.length) || (currentPageIndex === undefined)){ 
        index = pages.length - 1;
        setCurrentPageIndex(index);
      }
      fetchAllChartData(index);
      console.log('selectedPeriod - ' + selectedPeriod + ' customStartDate - ' + customStartDate + ' customEndDate - ' + customEndDate + ' currentPageIndex - ' + currentPageIndex); 
    }
  }, [selectedPeriod, customStartDate, customEndDate, currentPageIndex, initialLoad]);

  useEffect(() => {
    if (showChartSettingsForm) {
      setNewChartPanelVisible(true);
    }
  }, [showChartSettingsForm]);

  const chartDataAPI = useAPI('/api/appData/reportDoc/chart');

  useEffect(() => {
    if(chartDataAPI?.data) {
      const charts = chartDataAPI.data[0];
      // console.log('chartData - ' + JSON.stringify(charts));
      if (charts) {
        const parsedData = charts.details;
        setPages(parsedData);
        // Initialize chartData with empty arrays for each page
        setChartData(parsedData.map(() => []));
        // Save the id for future updates
        setChartId(charts.id);
      } else {
        setPages(defaultCharts);
        setChartData(defaultCharts.map(() => []));
      }
      setInitialLoad(false); // Set initial load to false after data is loaded
    }
  }, [chartDataAPI.data]);

  const addItem = async (chartType = 'bar', sampleChartData = null, chartTitle = '', queryOptions = { dataGroups: [{"dataType": 'GL Reports', "dataVal":"Balance","groupBy":"Account Type"}], showLegend: false }, color = '', targetValue = 0) => {
    const maxId = pages[currentPageIndex].charts.reduce((max, chart) => {
      const id = parseInt(chart.i.split('-')[1], 10);
      return id > max ? id : max;
    }, 0);
    const newChart = {
      i: `chart-${maxId + 1}`,
      x: 0,
      y: Infinity,
      w: 5,
      h: 8,
      chartType,
      targetValue,
      sampleChartData,
      chartTitle,
      queryOptions,
      color
    };
    const updatedPages = [...pages];
    updatedPages[currentPageIndex] = {
      ...updatedPages[currentPageIndex],
      charts: [...updatedPages[currentPageIndex].charts, newChart]
    };
    // console.log(JSON.stringify(updatedPages));
    setPages(updatedPages);
    onSaveChart && onSaveChart(newChart);
    setNewChartPanelVisible(false);

    // Fetch data for the new chart and update chartData
    const updatedItemWithData = await fetchChartData(newChart);
    // console.log('chartData - ' + JSON.stringify(chartData));
    const updatedChartData = [...chartData];
    if (!updatedChartData[currentPageIndex]) {
      updatedChartData[currentPageIndex] = [];
    }
    updatedChartData[currentPageIndex] = [
      ...updatedChartData[currentPageIndex],
      updatedItemWithData
    ];
    setChartData(updatedChartData);
  };

  const openNewChartPanel = () => {
    setEditingItem(null);
    setNewChartPanelVisible(true);
  };

  const closeNewChartPanel = () => {
    setNewChartPanelVisible(false);
  };

  const handleLayoutChange = (newLayout) => {
    const updatedPages = [...pages];
    updatedPages[currentPageIndex] = {
      ...updatedPages[currentPageIndex],
      charts: pages[currentPageIndex].charts.map(item => {
        const layoutItem = newLayout.find(l => l.i === item.i);
        return layoutItem ? { ...item, ...layoutItem } : item;
      })
    };
    setPages(updatedPages);
  };

  const handleMaximize = (item) => {
    setModalItem(item);
  };

  const handleEdit = (item, event) => {
    event.stopPropagation(); // Prevent the chart click handler from firing
    setEditingItem(item);
    setNewChartPanelVisible(true);
  };

  const handleDownload = (item) => {
    // Implement the logic to download the chart data to Excel
    console.log(`Downloading chart data for ${item.chartTitle}`);
  };

  const handleDelete = (item) => {
    if (window.confirm(`Are you sure you want to delete the chart?`)) {
      const updatedCharts = pages[currentPageIndex].charts.filter(chart => chart.i !== item.i);
      const updatedPages = [...pages];
      updatedPages[currentPageIndex] = {
        ...updatedPages[currentPageIndex],
        charts: updatedCharts
      };
      setPages(updatedPages);
    }
  };

  const handleDeletePage = (index) => {
    if (window.confirm(`Are you sure you want to delete this section?`)) {
      
      const updatedPages = pages.filter((_, i) => i !== index);
      setPages(updatedPages);
      if (index === currentPageIndex) {
        setCurrentPageIndex(updatedPages.length === 0 ? 0 : Math.min(currentPageIndex, updatedPages.length - 1));
      } else if (index === pages.length - 1) {
        setCurrentPageIndex(0);
      } else {
        setCurrentPageIndex(prevIndex => Math.min(prevIndex, updatedPages.length - 1));
      }
    }
  };

  const getPeriodDates = (period) => {
    const now = new Date();
    let start_date, end_date;

    switch (period) {
      case 'This Month':
        start_date = new Date(now.getFullYear(), now.getMonth(), 1);
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'Last Month':
        start_date = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end_date = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'This Quarter':
        const currentQuarter = Math.floor((now.getMonth() + 3) / 3);
        start_date = new Date(now.getFullYear(), (currentQuarter - 1) * 3, 1);
        end_date = new Date(now.getFullYear(), currentQuarter * 3, 0);
        break;
      case 'Last Quarter':
        const lastQuarter = Math.floor((now.getMonth() + 3) / 3) - 1;
        start_date = new Date(now.getFullYear(), (lastQuarter - 1) * 3, 1);
        end_date = new Date(now.getFullYear(), lastQuarter * 3, 0);
        break;
      case 'This Year':
        start_date = new Date(now.getFullYear(), 0, 1);
        end_date = new Date(now.getFullYear(), 11, 31);
        break;
      case 'Last 12 Months':
        start_date = new Date(now.getFullYear(), now.getMonth() - 11, 0);
        // console.log('start_date: ' + start_date)
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'Last 3 Years':
        start_date = new Date(now.getFullYear() - 3, 11, 31);
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'Last 5 Years':
        start_date = new Date(now.getFullYear() - 5, 11, 31);
        end_date = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      default:
        start_date = customStartDate;
        end_date = customEndDate;
        break;
    }

    return { start_date, end_date };
  };

  const fetchChartData = async (updatedItem) => {
    const { start_date, end_date } = getPeriodDates(selectedPeriod);
    let url = `/api/appData/reportData/${authContext.user.app_connection_id}?dataType=${updatedItem.queryOptions.dataType}&dataGroups=${JSON.stringify(updatedItem?.queryOptions?.dataGroups !== undefined ? updatedItem.queryOptions.dataGroups : [])}`;
    if (start_date && end_date) {
      url += `&start_date=${start_date.toISOString()}&end_date=${end_date.toISOString()}`;
    }
    if (updatedItem.queryOptions.dataGroups[0].rowLimit) {
      url += `&rowLimit=${updatedItem.queryOptions.dataGroups[0].rowLimit}`;
    }
    const response = await axios.get(url);
    const data = response.data.data;
    return { ...updatedItem, chartData: data };
  };

  useEffect(() => {
    const saveChartData = async () => {
      try {
        console.log('chartId - ' + chartId);
        await axios.post('/api/appData/reportData', {
          id: chartId, // Use the saved id for updates
          report_name: 'chart',
          details: pages,
          app_connection_id: authContext.user.app_connection_id
        });
      } catch (error) {
        console.error('Error saving chart settings:', error);
      }
    };

    if (!initialLoad && pages.length > 0) {
      saveChartData();
    }
  }, [pages]);

  const saveChartSettings = async (updatedItem) => {
    const updatedItemWithData = await fetchChartData(updatedItem);
    const isExistingChart = pages[currentPageIndex].charts.some(item => item.i === updatedItemWithData.i);

    if (isExistingChart) {
      const updatedCharts = pages[currentPageIndex].charts.map(item =>
        item.i === updatedItemWithData.i ? updatedItemWithData : item
      );
      const updatedPages = [...pages];
      updatedPages[currentPageIndex] = {
        ...updatedPages[currentPageIndex],
        charts: updatedCharts
      };
      setPages(updatedPages);
    } else {
      const updatedPages = [...pages];
      updatedPages[currentPageIndex] = {
        ...updatedPages[currentPageIndex],
        charts: [...updatedPages[currentPageIndex].charts, updatedItemWithData]
      };
      setPages(updatedPages);
      addItem(updatedItemWithData.chartType, updatedItemWithData.chartData, updatedItemWithData.chartTitle, updatedItemWithData.queryOptions, updatedItemWithData.color, updatedItemWithData.targetValue);
    }
    setEditingItem(null);
    setNewChartPanelVisible(false);
  };

  const closeModal = () => {
    setModalItem(null);
  };

  const closePanel = () => {
    setEditingItem(null);
    setNewChartPanelVisible(false);
  };

  const getChartOptions = (item) => {
    return {
      ...chartOptions,
      legend: {
        ...chartOptions.legend,
        display: item.queryOptions.showLegend
      },
      color: item.color
    };
  };

  const addNewPage = () => {
    const newPageIndex = pages.length;
    setPages([...pages, { name: `Page ${newPageIndex + 1}`, title: `Page ${newPageIndex + 1}`, charts: [] }]);
    setCurrentPageIndex(newPageIndex);
  };

  const goToPage = (index) => {
    if (index >= 0 && index < pages.length) {
      setCurrentPageIndex(index);
    }
  };

  const handleDragStart = (chartId) => {
    setDraggingChartId(chartId);
  };

  const handleDragEnd = () => {
    setDraggingChartId(null);
  };

  const handleTitleChange = (e) => {
    const updatedPages = [...pages];
    updatedPages[currentPageIndex].title = e.target.value;
    setPages(updatedPages);
  };

  const toggleEditingTitle = () => {
    setEditingTitle(!editingTitle);
  };

  const getTrimmedTitle = (title) => {
    return title.length > 22 ? `${title.substring(0, 22)}...` : title;
  };

  const toggleChartMenu = (chartId) => {
    setActiveChartMenu(activeChartMenu === chartId ? null : chartId);
  };

  const handleMouseLeave = (chartId) => {
    setHoveredChartId(null);
    if (activeChartMenu === chartId) {
      setActiveChartMenu(null);
    }
  };

  const generatePDF = async () => {
    const doc = new jsPDF('p', 'mm', 'a4');
    for (let i = 0; i < pages.length; i++) {
      setCurrentPageIndex(i);
      await new Promise(resolve => setTimeout(resolve, 500)); // Wait for the page to render
      const charts = chartData[i];
      const allDataLoaded = charts.every(chart => chart.chartData);
      if (!allDataLoaded) {
        await fetchAllChartDataForPage(i);
      }
      await new Promise(resolve => setTimeout(resolve, 500)); // Wait for charts to finish rendering
      const canvas = await html2canvas(document.querySelector('.layout'), { scale: 2 });
      const imgData = canvas.toDataURL('image/jpeg', 0.75); // Use JPEG format with 75% quality
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.text(pages[i].title, 10, 10); // Add the page title at the top
      doc.addImage(imgData, 'JPEG', 0, 20, pdfWidth, pdfHeight - 20); // Adjust the image position
      if (i < pages.length - 1) {
        doc.addPage();
      }
    }
    doc.save('report.pdf');
  };

  const fetchAllChartDataForPage = async (pageIndex) => {
    const updatedCharts = await Promise.all(
      pages[pageIndex].charts.map(async (item) => {
        const updatedItemWithData = await fetchChartData(item);
        return updatedItemWithData;
      })
    );
    const updatedChartData = [...chartData];
    updatedChartData[pageIndex] = updatedCharts;
    setChartData(updatedChartData);
  };

  const renderChart = (item, chartData) => {
    const renderTarget = item.targetValue && (item.chartType === 'bar' || item.chartType === 'line');
    console.log('renderTarget - ' + item.targetValue + ' chartType - ' + item.chartType);
    const chartProps = {
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: item.queryOptions.showLegend,
          position: 'right'
        },
        title: {
          display: true,
          text: item.chartTitle
        },
        tooltips: { enabled: true },
        animation: { duration: 1000 },
        ...(renderTarget === true && {
          scales: {
          yAxes: [
            {
              id: "y-axis-0",
              display: true,
              scaleLabel: {
                display: true,
                labelString: ""
              }
            }
          ]
        }
      }),
        annotation: {
          annotations: renderTarget ? [{
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: item.targetValue,
            borderColor: 'red',
            borderWidth: 2,
            borderDash: [10, 5],
            label: {
              enabled: true,
              content: `Target: ${item.targetValue}`
            }
          }] : []
        }
      }
    };

    switch (item.chartType) {
      case 'bar':
        return <Bar {...chartProps} />;
      case 'line':
        return <Line {...chartProps} />;
      case 'pie':
        return <Pie {...chartProps} />;
      case 'donut':
        return <Doughnut {...chartProps} />;
      default:
        return null;
    }
  };

  return (
    <>
    {/* <nav className='subnav'>
      <NavLink exact to='/app/report/dashboard' activeClassName='active'>Dashboard</NavLink>
      <NavLink exact to='/app/report/index' activeClassName='active'>Reports</NavLink>
    </nav> */}
    {/* Side panel for editing charts */}
    {newChartPanelVisible && (
              <div className="side-panel-rpt" style={{ height: 'calc(100vh - 40px)', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="side-panel-content" style={{ width: '100%', height: '100%', backgroundColor: '#fff', padding: '20px', boxSizing: 'border-box' }}>
                  <ChartSettingsForm item={editingItem || { chartType: 'bar', chartTitle: '', queryOptions: { dataGroups: [{"dataType": 'GL Reports', aggregation: 'SUM', "dataVal":"Balance","groupBy":"Account Type", "filters": []}], showLegend: false }, targetValue: 0, color: '' }} onSave={saveChartSettings} onCancel={closePanel} />
                </div>
              </div>
            )}
            {!newChartPanelVisible && (
      <div style={{ display: 'flex', height: 'calc(100vh - 40px)', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ width: '200px', backgroundColor: '#f4f4f4', padding: '10px', boxShadow: '2px 0 5px rgba(0,0,0,0.1)' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h3>Sections</h3>
              <button onClick={addNewPage} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <FeatherIcon icon="plus" size={20} />
              </button>
            </div>
            <DragDropContext onDragEnd={(result) => handlePageDragEnd(result, pages, setPages, setCurrentPageIndex)}>
              <Droppable droppableId="droppable-pages">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {pages.map((p, index) => (
                        <Draggable key={index} draggableId={`page-${index}`} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                marginBottom: '10px',
                                cursor: 'pointer',
                                padding: '5px',
                                backgroundColor: currentPageIndex === index ? '#ddd' : 'transparent',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: '24px',
                              }}
                              onClick={() => goToPage(index)}
                              onMouseEnter={() => setHoveredSectionIndex(index)}
                              onMouseLeave={() => setHoveredSectionIndex(null)}
                            >
                              {getTrimmedTitle(p.title)}
                              {hoveredSectionIndex === index && (
                                <button onClick={(e) => { e.stopPropagation(); handleDeletePage(index); }} style={{ background: 'none', border: 'none', cursor: 'pointer', paddingTop: '6px', color: 'inherit' }}>
                                <FeatherIcon icon="trash-2" size={16} />
                              </button>
                              )}
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div style={{ flex: 1, padding: '20px', position: 'relative', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', overflow: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div className="dropdown-container">
                <Dropdown value={selectedPeriod} options={dataPeriods.map(period => ({ label: period, value: period }))} onChange={(e) => setSelectedPeriod(e.value)} placeholder="Select Period" />
                {selectedPeriod === 'Custom' && (
                  <>
                    <Calendar value={customStartDate} onChange={(e) => setCustomStartDate(e.value)} view="month" dateFormat="mm/yy" placeholder="Start Date" />
                    <Calendar value={customEndDate} onChange={(e) => setCustomEndDate(e.value)} view="month" dateFormat="mm/yy" placeholder="End Date" />
                  </>
                )}
              </div>
              <div style={{ display: 'flex', gap: '20px' }}>
                <button onClick={openNewChartPanel} className="add-chart-button">Add New Chart</button>
                <button onClick={generatePDF} className="generate-pdf-button">Generate PDF</button>
              </div>
            </div>
            {editingTitle ? (
              <input
                type="text"
                value={pages[currentPageIndex].title}
                onChange={handleTitleChange}
                onBlur={toggleEditingTitle} // Ensure onBlur is set correctly
                autoFocus
                style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px', width: '100%' }}
              />
            ) : (
              <h2 onClick={toggleEditingTitle} style={{ cursor: 'pointer', marginBottom: '10px' }}>
                {pages[currentPageIndex].title}
              </h2>
            )}
            <GridLayout
              className="layout"
              layout={pages[currentPageIndex].charts}
              cols={12}
              rowHeight={30}
              width={1200}
              onLayoutChange={handleLayoutChange}
              onDragStart={(layout, oldItem) => handleDragStart(oldItem.i)}
              onDragStop={handleDragEnd}
              minW={5} // Minimum width of 5 cells
              minH={7} // Minimum height of 7 cells
            >
              {pages[currentPageIndex].charts.map((item, index) => (
                <div
                  style={{ zIndex: 100 }}
                  key={item.i}
                  data-grid={item}
                  className={`chart-item${draggingChartId === item.i ? ' dragging' : ''}`}
                  onMouseEnter={() => setHoveredChartId(item.i)}
                  onMouseLeave={() => handleMouseLeave(item.i)}
                >
                  <div className="chart-header" style={{ backgroundColor: item.color}}>
                    <h3>{item.chartTitle}</h3>
                    <div className="chart-toolbar">
                      {hoveredChartId === item.i && (
                        <button onClick={() => toggleChartMenu(item.i)}>
                          <FeatherIcon icon="chevron-down" size={16} color="white" />
                        </button>
                      )}
                      {activeChartMenu === item.i && (
                        <div className="chart-menu">
                          <button onClick={(event) => handleEdit(item, event)}>
                            <FeatherIcon icon="edit" size={16} /> Edit
                          </button>
                          <button onClick={() => handleDownload(item)}>
                            <FeatherIcon icon="download" size={16} /> Download
                          </button>
                          <button onClick={() => handleDelete(item)}>
                            <FeatherIcon icon="trash-2" size={16} /> Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="chart-container">
                    {renderChart(item, chartData[currentPageIndex] && chartData[currentPageIndex][index]?.chartData)}
                  </div>
                </div>
              ))}
            </GridLayout>

            {/* Modal for maximizing charts */}
            {modalItem && (
              <>
                <div className="modal-overlay" onClick={closeModal}></div>
                <div className="rpt-modal">
                  <button className="close-button" onClick={closeModal}>
                    <FeatherIcon icon="x" />
                  </button>
                  <h2>{modalItem.chartTitle}</h2>
                  <div className="chart-container">
                    {renderChart(modalItem, modalItem.chartData)}
                  </div>
                </div>
              </>
            )}

            
          </div>
        </div>
      </div>
      )}
    </>
  );
}