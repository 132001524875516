/***
*
*   HEADER
*   Header section with title used in main application (can render children)
*
*   PROPS
*   title: title of the view
*   children: children to render (optional)
*
**********/

import React, { useContext, useEffect, useState } from 'react'
import { AuthContext, ViewContext, HoverNav, Button, Notification } from 'components/lib';
import Style from './header.module.scss';
import apps from 'views/app/apps.json';
import {User} from './user';

export function Header(props){

  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const orgName = authContext.orgs?.find(x => x.id === authContext.user?.app_connection_id)?.name;
  const [orgList, setOrgList] = useState([])
  const [trialMsg, setTrialMsg] = useState(undefined);
  
  
    // function webSocketMsg(data) {
    //   console.log("header.webSocketMsg: " + data);
    //   viewContext.notification.show(data, 'success', true, 'toast');

    // }
    
    // useEffect(() => {
    //   // Assuming userId is passed as a prop to this component
    //   // console.log(JSON.stringify(authContext))
      
    //   //authContext.createWebSocket(webSocketMsg);
    //   authContext.wsMessageHandler((data) => (data) => webSocketMsg(data));
  
    // }, [authContext.user]); 

    
  useEffect(() => {

    
    if((authContext?.user?.plan === "free") && (authContext?.user?.trial_expires <= 0)) {
    viewContext.handleError({
      response: {
        data: {
          message: "Your trial has expired",
          plan: "free"
        },
        status: 402
      }
    })
    }
    else if(authContext?.user?.plan === "free") {
      setTrialMsg("You have " + authContext?.user?.trial_expires + " days left on your trial. ");
    }
  }, [authContext.user]);

  useEffect(() => {
    var orgListTmp = [];
    authContext.orgs.map((org) => {
      if(org.is_notification_conn == false) {
        orgListTmp.push(org);
      }
    })
    orgListTmp.push({
        name: '+ Add Data Connection',
        id: ''
    })
    setOrgList(orgListTmp)
  }, [authContext.orgs]);

  const getAppIcon = (appId) => {
    const app = apps.find(app => app.short_name === appId);
    return app ? app.icon : null;
  };

  const selectedOrg = authContext.orgs?.find(x => x.id === authContext.user?.app_connection_id);
  const selectedOrgIcon = selectedOrg ? getAppIcon(selectedOrg.app) : null;

  return (
    <>
    
    
{trialMsg &&
    <div style={{backgroundColor: 'orange',
top: '0px',
left: '0px',
right: '0px',
textAlign: 'center',
height: '30px',
paddingTop: '4px',
marginBottom: '24px',
marginTop: '-24px',
marginLeft: '-20px',
marginRight: '-24px'
}}>{trialMsg} <a href="/profile/billing">Buy Now</a></div>
}
    {!authContext.user &&
    <div style={{backgroundColor: 'orange', height: '40px', paddingTop: '10px', margin: '-1.5625em -1.5625em 1.5625em -1.5625em', color: 'white', textAlign: 'center'}}>{authContext.user.plan}</div>
    }
    <header className={ Style.header }>
<div style={{
    
}}>
  

      { props.title && <h1 style={{marginTop: -5}}>{ props.title }</h1> }

{authContext.orgs?.length && props.layout !== "firm" ? 
      <HoverNav
          dark
          image={ selectedOrgIcon } 
          label={ orgName } 
          className={ Style.hoverNav }
          align='left' >
          { orgList.map(org => {
              const appIcon = getAppIcon(org.app);
              return (
                <Button 
                  key={ org.id }
                  text={ 
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      { appIcon && <img src={ appIcon } alt={ org.name } style={{ width: 15, height: 15, marginRight: 5 }} /> }
                      { org.name }
                    </span>
                  }
                  action={() => {
                    viewContext.setLoading(true);
                    if(org.id == '') {
                      window.location = '/app/app_connection'
                    } else {
                      authContext.switchApp(org.id);
                    }
                  }}
                />
              );
            }
          )}
        </HoverNav>
        :
        <div></div>
          }
        </div>
        <User layout={props.layout}/>
        { props.children }

    </header>
    </>
  );
}
