 const loadedTasks = [
  { id: 1, title: 'Task 1', description: '<p><strong>Description for Task 1</strong></p>', status: 'Pending', due_date: new Date('2023-12-01'), new_comments: 1, task_group: 'Group 1', assigned_to: 'John Doe', order: 0, subtasksList: [{ id: 4, title: 'Subtask 1', status: 'Pending', due_date: new Date('2023-12-01'), assigned_to: 'User 1', activities: [] }, { id: 7, title: 'Subtask 2', status: 'Pending', due_date: new Date('2023-12-02'), assigned_to: 'User 2', activities: [] }, { id: 8, title: 'Subtask 3', status: 'Pending', due_date: new Date('2023-12-03'), assigned_to: 'User 3', activities: [] }], commentsList: [{ user: 'User 2', time: '2023-11-01T10:00:00Z', message: 'This is a comment.' }], tags: ['urgent', 'backend'], activities: [] },
  { id: 2, title: 'Task 2', description: '<p><em>Description for Task 2</em></p>', status: 'In Progress', due_date: new Date('2023-12-05'), new_comments: 0, task_group: 'Group 1', assigned_to: 'User 2', order: 0, subtasksList: [{ id: 5, title: 'Subtask 2', status: 'In Progress', due_date: new Date('2023-12-05'), assigned_to: 'User 2', activities: [] }, { id: 9, title: 'Subtask 3', status: 'In Progress', due_date: new Date('2023-12-06'), assigned_to: 'User 3', activities: [] }], commentsList: [], tags: ['frontend'], activities: [] },
  { id: 3, title: 'Task 3', description: '<p><u>Description for Task 3</u></p>', status: 'Blocked', due_date: new Date('2024-12-03'), new_comments: 0, task_group: 'Group 2', assigned_to: 'User 3', order: 0, subtasksList: [{ id: 6, title: 'Subtask 3', status: 'Completed', due_date: new Date('2023-12-10'), assigned_to: 'User 3', activities: [] }], commentsList: [], tags: ['urgent'], activities: [] },
  { id: 4, title: 'Task 4', description: 'Description for Task 4', status: 'Completed', due_date: new Date('2024-12-14'), new_comments: 0, task_group: 'Group 2', assigned_to: 'User 4', order: 0, subtasksList: [], commentsList: [], tags: [], activities: [] },
  { id: 5, title: 'Task 5', description: 'Description for Task 5', status: 'Repeat', due_date: new Date('2024-12-17'), new_comments: 0, task_group: 'Group 3', assigned_to: 'User 5', order: 0, subtasksList: [], commentsList: [], tags: ['backend'], activities: [] },
];

export const users = [
  'John Doe', 'User 2', 'User 3', 'User 4', 'User 5'
];

export const statuses = [
  { id: 'Not Started', title: 'Not Started' },
  { id: 'In Progress', title: 'In Progress' },
  { id: 'Blocked', title: 'Blocked' },
  { id: 'Completed', title: 'Completed' },
  { id: 'Repeat', title: 'Repeat' },
  { id: 'Not Completed', title: 'Not Completed' }
];

export const dueDateOptions = [
  { label: 'Due before today', value: 'beforeToday' },
  { label: 'Due today', value: 'today' },
  { label: 'Due tomorrow', value: 'tomorrow' },
  { label: 'Due this week', value: 'thisWeek' },
  { label: 'Due next week', value: 'nextWeek' },
  { label: 'Due in the next 14 days', value: 'next14Days' }
];

export const allTags = Array.from(new Set(loadedTasks.flatMap(task => task.tags)));
