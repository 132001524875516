import { Account } from 'views/profile';
import { Profile } from 'views/profile/profile';
import { Billing } from 'views/profile/billing';
import { Upgrade } from 'views/profile/upgrade';
import { Password } from 'views/profile/password';
import { TwoFA } from 'views/profile/2fa';

const Routes = [
  {
    path: '/profile',
    view: Account,
    layout: 'app',
    permission: 'user',
    title: 'Profile'
  },
  {
    path: '/profile/profile',
    view: Profile,
    layout: 'app',
    permission: 'user',
    title: 'Your Info'
  },
  {
    path: '/profile/password',
    view: Password,
    layout: 'app',
    permission: 'user',
    title: 'Change Password'
  },
  {
    path: '/profile/2fa',
    view: TwoFA,
    layout: 'app',
    permission: 'user',
    title: 'Manage 2FA'
  },
  {
    path: '/profile/billing',
    view: Billing,
    layout: 'app',
    permission: 'owner',
    title: 'Billing'
  },
  {
    path: '/profile/upgrade',
    view: Upgrade,
    layout: 'app',
    permission: 'user',
    title: 'Upgrade'
  }
]

export default Routes;